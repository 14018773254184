import React from 'react';
import PropTypes from 'prop-types';
import { IconStar } from './IconSvg';
import '../../styles/rating-stars-vote.scss';

const RatingStarsVote = ({ size, rating, clickFunc }) => {
    const clickStar = num => {
        clickFunc(num);
    };

    return (
        <div className="rating-stars-vote">
            <IconStar onClick={() => clickStar(1)} size={size} color={+rating >= 1} />
            <IconStar onClick={() => clickStar(2)} size={size} color={+rating >= 2} />
            <IconStar onClick={() => clickStar(3)} size={size} color={+rating >= 3} />
            <IconStar onClick={() => clickStar(4)} size={size} color={+rating >= 4} />
            <IconStar onClick={() => clickStar(5)} size={size} color={+rating >= 5} />
        </div>
    );
};

RatingStarsVote.propTypes = {
    size: PropTypes.number,
    rating: PropTypes.number,
    clickFunc: PropTypes.func,
};

export default RatingStarsVote;
