import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import CustomScroll from 'react-custom-scroll';
import { setTabCategory, setTabs } from '../store/globals/actions';
import { getCategoryInfo, resizeFrame } from '../services/vk';
import {
    homeSecondSetBestAuthors,
    homeSecondSetRecommendedCourses,
    homeSecondSetTopCourse,
} from '../store/home/actions';
import '../../styles/custom-select.scss';
import '../../styles/custom-scroll.scss';
import { authorsArrMock, coursesArrMock, publicsArrMock } from '../services/mock';

const CustomSelect = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.student.categories);
    const tabCategoryTitle = useSelector(state => state.globals.tabCategoryTitle);
    const tabCategoryIndex = useSelector(state => state.globals.tabCategoryIndex);
    const tabIndex = useSelector(state => state.globals.tabIndex);
    const [isHide, setIsHide] = useState(false);

    const removeHide = () => {
        if (isHide) {
            setIsHide(false);
        }
    };

    const selectCategory = async (id, title) => {
        setIsHide(true);

        dispatch(setTabCategory(id, title));
        dispatch(setTabs(2));

        // Получаем инфу
        if (process.env.NODE_ENV === 'development') {
            dispatch(homeSecondSetTopCourse(coursesArrMock().items.slice(0, 1)[0]));
            dispatch(homeSecondSetBestAuthors(authorsArrMock().items.slice(0, 5), publicsArrMock()));
            dispatch(homeSecondSetRecommendedCourses(coursesArrMock()));
        } else {
            const categoryInfoResult = await getCategoryInfo(id);

            if (categoryInfoResult.result === 'success') {
                dispatch(
                    homeSecondSetTopCourse(
                        categoryInfoResult.data.top.items[0] !== undefined ? categoryInfoResult.data.top.items[0] : {},
                    ),
                );
                dispatch(
                    homeSecondSetBestAuthors(
                        categoryInfoResult.data.authors.items.slice(0, 5),
                        categoryInfoResult.data.groups,
                    ),
                );
                dispatch(homeSecondSetRecommendedCourses(categoryInfoResult.data.recommended));
            }
        }

        resizeFrame();
    };

    const selectIfSelected = () => {
        if (tabCategoryTitle !== '') {
            selectCategory(tabCategoryIndex, tabCategoryTitle);
        }
    };

    const renderList = () =>
        categories.map(cat => (
            <div
                key={`csct${cat.id}`}
                onClick={() => selectCategory(cat.id, cat.name === '' ? cat.tag : cat.name)}
                className="custom-select--item"
            >
                {cat.name === '' ? cat.tag : cat.name}
            </div>
        ));

    return (
        <div
            className={`custom-select TabsItem${tabIndex === 2 ? ' TabsItem--selected' : ''}${isHide ? ' hide' : ''}`}
            onMouseMove={removeHide}
        >
            <TabsItem onClick={selectIfSelected}>
                {tabCategoryTitle !== '' ? tabCategoryTitle : 'Категории'}
                <div className="arrow-down" />
            </TabsItem>
            <div className="custom-select--between" />
            <div className="custom-select--items">
                <CustomScroll heightRelativeToParent="100%">{renderList()}</CustomScroll>
            </div>
        </div>
    );
};

export default CustomSelect;
