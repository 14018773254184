import * as types from './actionTypes';

export const openModal = modal => ({
    type: types.OPEN_MODAL,
    modal,
});

export const closeModal = () => ({
    type: types.CLOSE_MODAL,
});

export const setTabs = tabIndex => ({
    type: types.SET_TAB,
    tabIndex,
});

export const setSubTabs = subTabIndex => ({
    type: types.SET_SUBTAB,
    subTabIndex,
});

export const setStartInfoLoaded = () => ({
    type: types.SET_START_INFO_LOADED,
});

export const setTabCategory = (tabCategoryIndex, tabCategoryTitle) => ({
    type: types.SET_TAB_CATEGORY,
    tabCategoryIndex,
    tabCategoryTitle,
});

export const setIsFirstLoadStartInfo = isFirstLoadStartInfo => ({
    type: types.SET_IS_FIRST_LOAD_START_INFO,
    isFirstLoadStartInfo,
});

export const setIsNeedPreload = (preloadAuthorId, preloadCourseId, preloadStepId, isNeedPreload) => ({
    type: types.SET_IS_NEED_PRELOAD,
    preloadAuthorId,
    preloadCourseId,
    preloadStepId,
    isNeedPreload,
});
