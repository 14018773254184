import React from 'react';
import { useSelector } from 'react-redux';
import { icons } from '../services/icons';
import CategoryCheckItem from './CategoryCheckItem';
import '../../styles/category-list.scss';

const CategoryCheckItemList = () => {
    const categories = useSelector(state => state.student.categories);
    const selectedCategoriesIds = useSelector(state => state.student.selectedCategoriesIds);

    const renderLeftList = () => {
        return categories
            .filter((cat, i) => i < Math.ceil(categories.length / 2))
            .map(cat => (
                <CategoryCheckItem
                    id={cat.id}
                    icon={icons[cat.tag] !== undefined ? icons[cat.tag] : icons.other}
                    title={cat.name !== '' ? cat.name : cat.tag}
                    defaultChecked={selectedCategoriesIds.includes(cat.id)}
                />
            ));
    };

    const renderRightList = () => {
        return categories
            .filter((cat, i) => i >= Math.ceil(categories.length / 2))
            .map(cat => (
                <CategoryCheckItem
                    id={cat.id}
                    icon={icons[cat.tag] !== undefined ? icons[cat.tag] : icons.other}
                    title={cat.name !== '' ? cat.name : cat.tag}
                    defaultChecked={selectedCategoriesIds.includes(cat.id)}
                />
            ));
    };

    return (
        <div className="category-list">
            <div className="category-list--left">{renderLeftList()}</div>
            <div className="category-list--right">{renderRightList()}</div>
        </div>
    );
};

export default CategoryCheckItemList;
