import * as types from './actionTypes';

const initialState = {
    interests: [],
    categories: [],
    selectedCategoriesIds: [],
};

export const student = (state = initialState, action) => {
    switch (action.type) {
        case types.STUDENT_SET_INTERESTS: {
            const newSelectedCategoriesIds = action.interests.map(i => i.id);
            return {
                ...state,
                interests: action.interests,
                selectedCategoriesIds: newSelectedCategoriesIds,
            };
        }

        case types.STUDENT_SET_CATEGORIES: {
            const categoriesWithoutOther = action.categories.filter(cat => cat.tag !== 'other');

            return {
                ...state,
                categories: categoriesWithoutOther,
            };
        }

        case types.STUDENT_ADD_SELECTED_CATEGORY_ID: {
            const newSelectedIds = [...state.selectedCategoriesIds];
            newSelectedIds.push(action.selectedCategoryId);

            return {
                ...state,
                selectedCategoriesIds: newSelectedIds,
            };
        }

        case types.STUDENT_REMOVE_SELECTED_CATEGORY_ID: {
            const newSelectedIds = [...state.selectedCategoriesIds].filter(id => id !== action.deselectedCategoryId);

            return {
                ...state,
                selectedCategoriesIds: newSelectedIds,
            };
        }

        default:
            return state;
    }
};
