import React from 'react';
import PropTypes from 'prop-types';
import { numberWithSpaces, wordPad } from '../services/_functions';
import RatingStars from './RatingStars';
import '../../styles/marathon-item.scss';

const MarathonItem = ({ onClick, image, title, desc, rating, student, exercise }) => {
    const renderExercise = (
        <div className="marathon-item--amount">
            {exercise + wordPad(exercise, ' упражнение', ' упражнения', ' упражнений')}
        </div>
    );

    const renderStudent = numberWithSpaces(student) + wordPad(student, ' студент', ' студента', ' студентов');

    return (
        <div className="marathon-item">
            <div className="marathon-item--image" style={{ backgroundImage: `url(${image})` }} onClick={onClick}>
                {renderExercise}
            </div>
            <div className="marathon-item--content">
                <div className={`marathon-item--info${desc ? ' two-row' : ''}`}>
                    <div className="marathon-item--title" onClick={onClick}>
                        {title}
                    </div>
                    {desc ? <div className="marathon-item--description">{desc}</div> : ''}
                </div>
                <div className="marathon-item--footer">
                    <div className="marathon-item--desc">{renderStudent}</div>
                    <div className="marathon-home--icon">
                        <RatingStars size={9} rating={rating} />
                    </div>
                </div>
            </div>
        </div>
    );
};

MarathonItem.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.number,
    maxValue: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    time: PropTypes.number,
    student: PropTypes.number,
    fullTime: PropTypes.bool,
    favorite: PropTypes.bool,
    exercise: PropTypes.number,
    exerciseAmount: PropTypes.number,
    rating: PropTypes.number,
    courseEnd: PropTypes.string,
};

export default MarathonItem;
