import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/white-block.scss';
import '../../styles/breadcrumbs.scss';

const WhiteBlockBread = ({ breadcrumbs, aside, before, children }) => {
    return (
        <div className="white-block-main">
            <div className="white-block-main--head">
                <div className="breadcrumbs">{breadcrumbs}</div>
                {before}
                <div className="ml-a">{aside}</div>
            </div>
            <div className="white-block--content">{children}</div>
        </div>
    );
};

WhiteBlockBread.propTypes = {
    breadcrumbs: PropTypes.node,
    before: PropTypes.node,
    aside: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default WhiteBlockBread;
