import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import WhiteBlockBread from '../../components/WhiteBlockBread';
import CategoryCheckItemList from '../../components/CategoryCheckItemList';
import ModalError from '../../components/ModalError';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { resizeFrame, setStudentInterest } from '../../services/vk';
import { openModal } from '../../store/globals/actions';
import '../../../styles/interests.scss';

const PanelInterests = () => {
    const dispatch = useDispatch();
    const selectedCategoriesIds = useSelector(state => state.student.selectedCategoriesIds);
    const isNeedPreload = useSelector(state => state.globals.isNeedPreload);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        resizeFrame();
    }, []);

    const saveStudentInterests = async () => {
        setIsLoading(true);
        const setInterestResult = await setStudentInterest(selectedCategoriesIds);
        setIsLoading(false);

        if (setInterestResult.result === 'success' && setInterestResult.data === 1) {
            if (!isNeedPreload) {
                dispatch(push('/home'));
            } else {
                dispatch(push('/marathon-about'));
            }
        } else {
            dispatch(openModal(<ModalError title={'Произошла неизвестная ошибка'} />));
        }
    };

    return (
        <WhiteBlockBread breadcrumbs={<BreadcrumbsItem title="VK Edu" link="" />}>
            <div className="onboard">
                <div className="onboard--title">Выбор категории</div>
                <div className="onboard--subtitle">Выберите несколько интересующих Вас тем</div>
                <div className="onboard--content">
                    <CategoryCheckItemList />
                </div>
                <div className="tooltip-btn">
                    <ButtonWithLoader
                        className="block-center"
                        disabled={selectedCategoriesIds.length < 3}
                        onClickFunc={saveStudentInterests}
                        isLoading={isLoading}
                    >
                        Продолжить
                    </ButtonWithLoader>
                    {selectedCategoriesIds.length < 3 && (
                        <div className="tooltip-btn--box">Выберите хотя бы три категории</div>
                    )}
                </div>
            </div>
        </WhiteBlockBread>
    );
};

PanelInterests.propTypes = {
    routePush: PropTypes.func,
};

export default PanelInterests;
