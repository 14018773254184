import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import { studentAddSelectedCategoryId, studentRemoveSelectedCategoryId } from '../store/student/actions';

const CategoryCheckItem = ({ id, icon, title, defaultChecked }) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(defaultChecked);

    const changeCheckbox = () => {
        if (checked) {
            setChecked(false);
            dispatch(studentRemoveSelectedCategoryId(id));
        } else {
            setChecked(true);
            dispatch(studentAddSelectedCategoryId(id));
        }
    };

    return (
        <Checkbox checked={checked} onChange={changeCheckbox}>
            <div className="icon-category" style={{ backgroundImage: `url(${icon})` }} /> {title}
        </Checkbox>
    );
};

CategoryCheckItem.propTypes = {
    id: PropTypes.number,
    icon: PropTypes.string,
    title: PropTypes.string,
    defaultChecked: PropTypes.bool,
};

export default CategoryCheckItem;
