import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';

const AnswerOption = ({ id, text, changeFunc, disabled, defaultChecked = false }) => {
    const [isChecked, setChecked] = useState(false);

    const checkClick = () => {
        changeFunc(id, !isChecked);
        setChecked(!isChecked);
    };

    return (
        <Checkbox disabled={disabled} defaultChecked={defaultChecked} onChange={checkClick} className="checkbox-custom">
            {text}
        </Checkbox>
    );
};

AnswerOption.propTypes = {
    id: PropTypes.number,
    text: PropTypes.string,
    changeFunc: PropTypes.func,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
};

export default AnswerOption;
