import React from 'react';
import { useSelector } from 'react-redux';
import WhiteBlock from './WhiteBlock';
import MarathonProgramItem from './MarathonProgramItem';

const MarathonProgramList = () => {
    const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);

    const renderList = () =>
        aboutCourseSteps.map((c, i) => (
            <MarathonProgramItem
                num={i}
                key={`pil${c.course_id}${c.author_id}`}
                name={c.title}
                test={c.has_test !== undefined ? c.has_test : false}
                testDone={c.is_test_passed !== undefined ? c.is_test_passed : false}
                done={c.is_article_read !== undefined ? c.is_article_read : false}
                articleUrl={c.article_url}
            />
        ));

    return (
        <WhiteBlock title="Программа">
            <div className="marathon-program">{renderList()}</div>
        </WhiteBlock>
    );
};

export default MarathonProgramList;
