// Падеж
import { getStepTest, openArticle, resizeFrame, submitArticle } from './vk';
import { push } from 'react-router-redux';
import {
    setTestShowOnly,
    testRemoveAnsweredQuestions,
    testReset,
    testSetBreadcumb,
    testSetStepId,
    testSetTest,
    testSetTestsWithoutAnswers,
} from '../store/test/actions';
import {
    homeAboutSetCourseStepsIsArticleRead,
    homeAboutSetSelectedMarathonIsCompleteCourse,
} from '../store/home/actions';
import { openModal } from '../store/globals/actions';
import ModalDoneMarathon from '../components/ModalDoneMarathon';
import React from 'react';

export const wordPad = (num, t, ta, tov) => {
    num %= 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) {
        return t;
    }
    if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) {
        return ta;
    }
    return tov;
};

// Нули для больших чисел
export const numberWithSpaces = x => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

// Получаем ID приложения
export const getAppId = () => {
    return +new URL(window.location.href).searchParams.get('vk_app_id');
};

// Получаем VK id
export const getVkId = () => {
    return +new URL(window.location.href).searchParams.get('vk_user_id');
};

// Получаем ID приложения
export const getCurrentDateTime = () => {
    const now = new Date();
    const day = now.getDate();
    let month = (now.getMonth() + 1).toString();
    if (month.length === 1) {
        month = `0${month}`;
    }
    const year = now
        .getFullYear()
        .toString()
        .substr(2, 2);
    const hours = now.getHours();
    let minutes = now.getMinutes().toString();
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    return `${day}.${month}.${year} в ${hours}:${minutes}`;
};

// Создаем фейк файл
export const makeFakeFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

// Скопировать массив
export const copyArray = array => JSON.parse(JSON.stringify(array));

// Пауза
export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

// Реквест ид для запросов ВК
export const getNewRequestId = () => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();

// Случайное число int от и до
export const randomInteger = (min, max) => Math.floor(min + Math.random() * (max + 1 - min));

// Преобразовать число в строку с тысячами (К) и миллионами (М)
export const numberWithKM = num => {
    if (num > 999 && num <= 999999) {
        return Math.floor(num / 100) / 10 + 'K';
    } else if (num > 999999) {
        return Math.floor(num / 100000) / 10 + 'M';
    } else {
        return num;
    }
};

// Открываем тест
export const openTest = (hasTest, articleUrl, title, isCourseComplete = false) => async dispatch => {
    const readResult = await openArticle(articleUrl);
    dispatch(setTestShowOnly(false));
    if (readResult.status !== undefined && readResult.status === 'read') {
        // Если курс пройден
        if (isCourseComplete) {
            dispatch(setTestShowOnly(true));
        }
        const submitResult = await submitArticle(
            readResult.data.author_id,
            readResult.data.course_id,
            readResult.data.step_id,
        );

        if (submitResult.result === 'success') {
            dispatch(homeAboutSetCourseStepsIsArticleRead(readResult.data.step_id));
            resizeFrame();
            if (hasTest) {
                if (submitResult.data.state.status === 'STEP_DONE') {
                    dispatch(setTestShowOnly(true));
                }
                dispatch(push('/test'));
                dispatch(testReset());
                const test = await getStepTest(
                    readResult.data.author_id,
                    readResult.data.course_id,
                    readResult.data.step_id,
                );
                dispatch(testSetTest(test.data));
                dispatch(testSetStepId(readResult.data.step_id));
                dispatch(testSetTestsWithoutAnswers(submitResult.data.state.question_ids));
                if (submitResult.data.state.status === 'NEED_RESOLVE_TEST') {
                    dispatch(testRemoveAnsweredQuestions());
                }
                dispatch(testSetBreadcumb(title));
                resizeFrame();
            } else {
                if (submitResult.data.state.status === 'COURSE_DONE') {
                    if (!isCourseComplete) {
                        dispatch(
                            openModal(
                                <ModalDoneMarathon
                                    questionFrom={submitResult.data.state.course_correct_answers_count}
                                    questionTo={submitResult.data.state.course_answers_count}
                                />,
                            ),
                        );
                    }
                }
            }
        }
    }
};
