import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { closeModal, openModal } from '../store/globals/actions';
import { setStepRating } from '../services/vk';
import RatingStarsVote from './RatingStarsVote';
import '../../styles/modal.scss';
import { openTest } from '../services/_functions';

const ModalDoneExercise = ({ questionFrom, questionTo, nextModal }) => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);
    const stepId = useSelector(state => state.test.stepId);
    const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
    const [voteStars, setVoteStars] = useState(0);
    const [nearestStep, setNearestStep] = useState(null);

    useEffect(() => {
        let isFond = false;
        aboutCourseSteps.forEach(step => {
            if (!isFond) {
                if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
                    setNearestStep(step);
                    isFond = true;
                }
            }
        });
    }, []);

    const closeModalOverlay = e => {
        if (e.target.classList.contains('modal--overlay')) {
            dispatch(closeModal());
        }
    };

    const closeMdl = () => {
        dispatch(closeModal());
        if (nextModal !== null) {
            dispatch(openModal(nextModal));
        }
    };

    const vote = async num => {
        setVoteStars(num);
        await setStepRating(selectedMarathon.author_id, selectedMarathon.course_id, stepId, num);
    };

    const openNextTest = () => {
        dispatch(closeModal());
        dispatch(openTest(nearestStep.has_test, nearestStep.article_url, nearestStep.title));
    };

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content">
                <div className="modal--block modal--done">
                    <div className="modal--header bg-white">
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="828a99" />
                        </div>
                    </div>
                    <div className="modal--body">
                        <div className="modal--title">Упражнение завершено</div>
                        <div className="modal--text">
                            <p>Вы успешно завершили упражнение.</p>
                            <p>
                                Правильных ответов {questionFrom}/{questionTo}
                            </p>
                        </div>
                        <div className="modal--rating">
                            <span>Оцените упражнение</span>
                            <RatingStarsVote rating={voteStars} size={22} clickFunc={vote} />
                        </div>
                        {nextModal === null && (
                            <div className="modal--footer">
                                <Button onClick={openNextTest}>К следующему упражнению</Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalDoneExercise.propTypes = {
    questionFrom: PropTypes.number,
    questionTo: PropTypes.number,
    nextModal: PropTypes.node,
};

export default ModalDoneExercise;
