import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import { createStudent, resizeFrame } from '../../services/vk';
import { openModal } from '../../store/globals/actions';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import WhiteBlockBread from '../../components/WhiteBlockBread';
import ModalError from '../../components/ModalError';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import '../../../styles/welcome.scss';

const PanelWelcome = () => {
    const dispatch = useDispatch();
    const [isAccept, setIsAccept] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        resizeFrame();
    }, []);

    const clickBegin = async () => {
        setIsLoading(true);
        const createStudentResult = await createStudent();
        setIsLoading(false);

        if (createStudentResult.result === 'success' && createStudentResult.data === 1) {
            dispatch(push('/interests'));
        } else {
            dispatch(openModal(<ModalError title={'Произошла неизвестная ошибка'} />));
        }
    };

    const changeCheckbox = () => {
        setIsAccept(!isAccept);
    };

    return (
        <WhiteBlockBread breadcrumbs={<BreadcrumbsItem title="VK Edu" link="" />}>
            <div className="welcome welcome-one welcome--center">
                <div className="welcome--image" />
                <div className="welcome--title">Учитесь ВКонтакте</div>
                <div className="welcome--subtitle">
                    Выбирайте образовательные марафоны,
                    <br /> занимайтесь каждый день
                </div>
                <Checkbox checked={isAccept} onChange={changeCheckbox}>
                    Принимаю&nbsp;
                    <a href="https://vk.com" target="_blank">
                        условия оферты
                    </a>
                </Checkbox>
                <ButtonWithLoader isLoading={isLoading} disabled={!isAccept} onClickFunc={clickBegin}>
                    Начать обучение
                </ButtonWithLoader>
            </div>
        </WhiteBlockBread>
    );
};

PanelWelcome.propTypes = {
    routePush: PropTypes.func,
};

export default PanelWelcome;
