import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import { COURSES_RECOMMENDED_PER_PAGE } from '../services/_globals';
import WhiteBlock from './WhiteBlock';
import MarathonItem from './MarathonItem';
import Pagination from './Pagination';
import { homeAboutSetIsNextCourseStart, homeSelectMarathon } from '../store/home/actions';
import '../../styles/marathon-item.scss';

const MarathonItemListPag = ({ title = 'Рекомендации', items, total, offset, selectPageFunc, active }) => {
    const dispatch = useDispatch();

    const openCourse = course => {
        dispatch(homeSelectMarathon(course));
        if (active) {
            dispatch(homeAboutSetIsNextCourseStart(true));
        }
        dispatch(push('/marathon-about'));
    };

    const renderList = () =>
        items.map(c => (
            <MarathonItem
                key={`mip${c.author_id}${c.course_id}`}
                onClick={() => openCourse(c)}
                image={c.cover_photo !== undefined && c.cover_photo[0].b !== undefined ? c.cover_photo[0].b.url : ''}
                title={c.title}
                exercise={c.step_count !== undefined ? c.step_count : 0}
                student={c.students_count}
                rating={c.rating}
            />
        ));

    const pagination = (
        <Pagination
            selectPageFunc={selectPageFunc}
            pageNum={Math.floor(offset / COURSES_RECOMMENDED_PER_PAGE) + 1}
            total={total}
        />
    );

    return (
        <WhiteBlock title={title === undefined ? '' : title} aside={<div className="pagination">{pagination}</div>}>
            <div className="marathon-item--list">
                <div className="marathon-item--list-inner">{renderList()}</div>
            </div>
            <div className="pagination pagination--bottom">{pagination}</div>
        </WhiteBlock>
    );
};

MarathonItemListPag.propTypes = {
    selectPageFunc: PropTypes.func,
    items: PropTypes.array,
    total: PropTypes.number,
    offset: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
};

export default MarathonItemListPag;
