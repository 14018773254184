import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { closeModal } from '../store/globals/actions';
import { studentStartCourse } from '../services/vk';
import { homeAboutSetSelectedMarathonIsActiveCourse } from '../store/home/actions';
import { openTest } from '../services/_functions';
import '../../styles/modal.scss';
import '../../styles/modal-subs.scss';

const ModalSubs = ({ id, top }) => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);
    const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
    const [nearestStep, setNearestStep] = useState(null);

    useEffect(() => {
        let isFond = false;
        aboutCourseSteps.forEach(step => {
            if (!isFond) {
                if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
                    setNearestStep(step);
                    isFond = true;
                }
            }
        });
    }, []);

    const closeModalOverlay = e => {
        if (e.target.classList.contains('modal--overlay')) {
            dispatch(closeModal());
        }
    };

    const closeMdl = () => {
        dispatch(closeModal(null));
    };

    const joinMarathon = async () => {
        const startResult = await studentStartCourse(-id, selectedMarathon.course_id);
        if (startResult.result === 'success' && startResult.data === 1) {
            dispatch(homeAboutSetSelectedMarathonIsActiveCourse(true));
        }
    };

    const clickLater = () => {
        joinMarathon();
        closeMdl();
    };

    const clickGo = () => {
        joinMarathon();
        closeMdl();
        dispatch(openTest(nearestStep.has_test, nearestStep.article_url, nearestStep.title));
    };

    const style = top !== undefined ? { top, transform: 'translateX(-50%)' } : null;

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content" style={style}>
                <div className="modal--block">
                    <div className="modal--header">
                        <span>Подписка на курс</span>
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="fff" />
                        </div>
                    </div>
                    <div className="modal-del--body">
                        Начать прохождение курса «{selectedMarathon.title}»? Вы можете начать прохождение позже в Вашем
                        профиле.
                    </div>
                    <div className="modal-del--footer">
                        <Button mode="tertiary" onClick={clickLater}>
                            Пройти позже
                        </Button>
                        {nearestStep !== null && <Button onClick={clickGo}>Перейти к прохождению</Button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalSubs.propTypes = {
    id: PropTypes.number,
    top: PropTypes.number,
};

export default ModalSubs;
