import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import ModalSubs from './ModalSubs';
import { closeModal, openModal } from '../store/globals/actions';
import '../../styles/modal.scss';
import '../../styles/modal-subs.scss';

const ModalNeedPodpiska = ({ top }) => {
    const aboutCoursePublic = useSelector(state => state.home.aboutCoursePublic);
    const dispatch = useDispatch();

    const closeModalOverlay = e => {
        if (e.target.classList.contains('modal--overlay')) {
            dispatch(closeModal(''));
        }
    };

    const closeMdl = () => {
        dispatch(closeModal(null));
    };

    const clickSubscribe = () => {
        closeMdl();
        dispatch(openModal(<ModalSubs id={aboutCoursePublic.id} top={150} />));
    };

    const style = top !== undefined ? { top, transform: 'translateX(-50%)' } : null;

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content" style={style}>
                <div className="modal--block">
                    <div className="modal--header">
                        <span>Подпишитесь на марафон</span>
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="fff" />
                        </div>
                    </div>
                    <div className="modal-del--body">Для доступа к упражнениям нужна подписка на марафон</div>
                    <div className="modal-del--footer">
                        <Button mode="tertiary" onClick={closeMdl}>
                            Отмена
                        </Button>
                        <Button onClick={clickSubscribe}>Подписаться</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalNeedPodpiska.propTypes = {
    top: PropTypes.number,
};

export default ModalNeedPodpiska;
