import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/white-block.scss';

const WhiteBlock = ({ title, before, aside, children }) => {
    return (
        <div className="white-block">
            {title ? (
                <>
                    <div className="white-block--name">
                        <span>{title}</span>
                        {before}
                        {aside}
                    </div>
                </>
            ) : (
                ''
            )}
            <div className="white-block--content">{children}</div>
        </div>
    );
};

WhiteBlock.propTypes = {
    title: PropTypes.string,
    before: PropTypes.node,
    aside: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default WhiteBlock;
