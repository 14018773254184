import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import RatingStars from './RatingStars';
import { numberWithSpaces, wordPad } from '../services/_functions';
import { homeSelectMarathon } from '../store/home/actions';
import { goToTop } from '../services/vk';
import '../../styles/white-block.scss';
import '../../styles/block-top-marathon.scss';

const CategoryTopMarathon = ({ image, title, student, rating, exercise, course }) => {
    const dispatch = useDispatch();

    const openMarathonAbout = () => {
        dispatch(homeSelectMarathon(course));
        dispatch(push('/marathon-about'));
        goToTop();
    };

    return (
        <div className="top-marathon" onClick={openMarathonAbout}>
            <div className="top-marathon--image" style={{ backgroundImage: `url(${image})` }}>
                <div className="top-marathon--info">
                    <div className="top-marathon--title">{title}</div>
                    <div className="top-marathon--bottom">
                        <div className="top-marathon--numbers">
                            <div className="top-marathon--ppl">
                                {numberWithSpaces(student) + wordPad(student, ' студент', ' студента', ' студентов')}
                            </div>
                            <RatingStars colWhite size={12} rating={rating} />
                        </div>
                        <div className="top-marathon--exercises">
                            {exercise + wordPad(exercise, ' упражнение', ' упражнения', ' упражнений')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CategoryTopMarathon.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    student: PropTypes.number,
    rating: PropTypes.number,
    exercise: PropTypes.number,
    course: PropTypes.object,
};

export default CategoryTopMarathon;
