import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import { closeModal, setTabs } from '../store/globals/actions';
import { homeAboutSetSelectedMarathonIsCompleteCourse } from '../store/home/actions';
import '../../styles/modal.scss';
import { resizeFrame, wallPost } from '../services/vk';

const ModalDoneMarathon = ({ questionFrom, questionTo }) => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);
    const aboutCoursePublic = useSelector(state => state.home.aboutCoursePublic);

    useEffect(() => {
        dispatch(homeAboutSetSelectedMarathonIsCompleteCourse(true));
        resizeFrame();
    }, []);

    const closeModalOverlay = e => {
        if (e.target.classList.contains('modal--overlay')) {
            dispatch(closeModal());
        }
    };

    const closeMdl = () => {
        dispatch(closeModal());
    };

    const openMarathons = () => {
        dispatch(push('/home'));
        dispatch(setTabs(1));
        dispatch(closeModal());
    };

    const share = () => {
        wallPost(selectedMarathon.title, aboutCoursePublic.id, aboutCoursePublic.name, questionFrom, questionTo);
    };

    return (
        <div className="modal--overlay" onClick={e => closeModalOverlay(e)}>
            <div className="modal--content">
                <div className="modal--block modal--done">
                    <div className="modal--header bg-white">
                        <div className="modal--close" onClick={closeMdl}>
                            <Icon24Cancel fill="828a99" />
                        </div>
                    </div>
                    <div className="modal--body">
                        <div className="modal--title">Марафон завершен</div>
                        <div className="modal--text">
                            <p>Вы успешно завершили марафон.</p>
                            {questionFrom !== 0 ||
                                (questionTo !== 0 && (
                                    <p>
                                        Правильных ответов {questionFrom}/{questionTo}
                                    </p>
                                ))}
                        </div>
                        <div className="modal--footer modal--footer-two">
                            <Button onClick={share}>Поделиться с друзьями</Button>
                            <Button mode="tertiary" onClick={openMarathons}>
                                К другим марафонам
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalDoneMarathon.propTypes = {
    questionFrom: PropTypes.number,
    questionTo: PropTypes.number,
};

export default ModalDoneMarathon;
