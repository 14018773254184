import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/best-author.scss';
import { numberWithKM, wordPad } from '../services/_functions';

const BlockBestAuthor = ({ image, title, ppl, onClick }) => {
    return (
        <div className="best-author--item" onClick={onClick}>
            <div className="best-author--image" style={{ backgroundImage: `url(${image})` }} />
            <div className="best-author--title">{title}</div>
            <div className="best-author--ppl">
                {ppl !== undefined && numberWithKM(ppl) + wordPad(ppl, ' подписчик', ' подписчика', ' подписчиков')}
                {ppl === undefined && 'Закрытая группа'}
            </div>
        </div>
    );
};

BlockBestAuthor.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    ppl: PropTypes.number,
    onClick: PropTypes.func,
};

export default BlockBestAuthor;
