import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Search from '@vkontakte/vkui/dist/components/Search/Search';
import {
    homeClearSearchCourses,
    homeSetSearchCourses,
    homeSetSearchIsLoading,
    homeSetSearchText,
} from '../store/home/actions';
import { getSearchCourses, resizeFrame } from '../services/vk';
import { coursesArrMock } from '../services/mock';
import '../../styles/search.scss';

const BlockSearch = () => {
    const dispatch = useDispatch();
    const searchText = useSelector(state => state.home.search.searchText);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const changeText = e => {
        const text = e.target.value;
        dispatch(homeSetSearchText(text));
        clearTimeout(searchTimeout);
        if (text.length >= 3) {
            dispatch(homeSetSearchIsLoading(true));
            setSearchTimeout(setTimeout(() => searchCoursesProcess(text), 500));
        } else {
            dispatch(homeClearSearchCourses());
            dispatch(homeSetSearchIsLoading(false));
            resizeFrame();
        }
    };

    const clearText = () => {
        dispatch(homeSetSearchText(''));
        clearTimeout(searchTimeout);
        dispatch(homeClearSearchCourses());
    };

    const searchCoursesProcess = async text => {
        if (process.env.NODE_ENV === 'development') {
            // dispatch(homeSetSearchCourses(coursesArrMock()));
        } else {
            dispatch(homeSetSearchIsLoading(true));
            const searchCoursesResult = await getSearchCourses(text, 0);
            if (searchCoursesResult.result === 'success') {
                dispatch(homeSetSearchCourses(searchCoursesResult.data));
                resizeFrame();
            }
            dispatch(homeSetSearchIsLoading(false));
        }
    };

    useEffect(() => {
        const tooltip = document.querySelector('.tooltip-search');
        const clear = document.querySelector('.Search__after');
        const isHover = e => e.parentElement.querySelector(':hover') === e;
        document.addEventListener('mousemove', () => {
            const hovered = isHover(clear);
            if (hovered) {
                tooltip.classList.add('active');
            } else {
                tooltip.classList.remove('active');
            }
        });
    });

    return (
        <div className="search-custom">
            <Search value={searchText} onChange={changeText} onCancel={clearText} />
            <div className="tooltip-search">Очистить поиск</div>
        </div>
    );
};

export default BlockSearch;
