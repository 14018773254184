import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import '@vkontakte/vkui/dist/vkui.css';
import Routing from './js/classes/Routing';
import { getAuthToken, getCategories, getCourse, getStartInfo, getStudent, initApp } from './js/services/vk';
import { getAppId, getVkId, sleep } from './js/services/_functions';
import { setAppId, setAuthTokenSuccess, setVkId } from './js/store/vk/actions';
import { setIsFirstLoadStartInfo, setIsNeedPreload, setStartInfoLoaded } from './js/store/globals/actions';
import { studentSetCategories, studentSetInterests } from './js/store/student/actions';
import { authorsArrMock, categoriesArrMock, coursesArrMock, publicsArrMock } from './js/services/mock';
import {
    homeMarathonsSetBestAuthors,
    homeMarathonsSetRecommendedCourses,
    homeMarathonsSetStudentActiveCourses,
    homeSelectMarathon,
} from './js/store/home/actions';
import './styles/style.scss';

const App = ({ pageId }) => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.globals.modal);
    const accessToken = useSelector(state => state.vk.accessToken);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            dispatch(setAuthTokenSuccess('token_mock'));
            dispatch(studentSetCategories(categoriesArrMock()));
            dispatch(homeMarathonsSetStudentActiveCourses(coursesArrMock().items.slice(0, 3)));
            dispatch(homeMarathonsSetBestAuthors(authorsArrMock().items.slice(0, 5), publicsArrMock()));
            dispatch(homeMarathonsSetRecommendedCourses(coursesArrMock()));

            // Только для тестов
            dispatch(push('/interests'));

            dispatch(setStartInfoLoaded());
        } else {
            (async () => {
                await dispatch(setAppId(getAppId()));
                await dispatch(setVkId(getVkId()));
                await dispatch(getAuthToken());
            })();
        }
    }, []);

    useEffect(() => {
        // Получаем данные об открытии артикля напрямую
        let authorId = null;
        let courseId = null;
        let stepId = null;
        let isNeedPreload = false;
        const href = window.location.href;

        if (href.indexOf('#') !== -1) {
            const rightHref = href.split('#')[1];
            const urlParams = new URLSearchParams(rightHref);
            authorId = urlParams.get('author_id');
            courseId = urlParams.get('course_id');
            stepId = urlParams.get('step_id');
        }

        if (authorId !== null && courseId !== null && stepId !== null) {
            dispatch(setIsNeedPreload(authorId, courseId, stepId, true));
            isNeedPreload = true;
        }

        if (accessToken !== 0) {
            (async () => {
                // Проверяем существует ли пользователь и отправляем его на нужную страницу
                const getOpenNextPage = await getStudentInfo();

                // Получаем категории интересов
                const categories = await getAllCategories();
                dispatch(studentSetCategories(categories.items));

                // Получаем стартовую инфу
                if (!isNeedPreload) {
                    const startInfoResult = await getStartInfo();
                    if (startInfoResult.result === 'success') {
                        dispatch(homeMarathonsSetStudentActiveCourses(startInfoResult.data.active.items.slice(0, 3)));
                        dispatch(
                            homeMarathonsSetBestAuthors(
                                startInfoResult.data.authors.items.slice(0, 5),
                                startInfoResult.data.groups,
                            ),
                        );
                        dispatch(homeMarathonsSetRecommendedCourses(startInfoResult.data.recommended));
                    }
                } else {
                    const course = await getCourse(authorId, courseId);
                    if (course.result === 'success') {
                        dispatch(homeSelectMarathon(course.data.course));
                    }
                    dispatch(setIsFirstLoadStartInfo(false));
                }

                // Открываем нужную страницу
                if (isNeedPreload) {
                    if (getOpenNextPage !== 'home') {
                        dispatch(push(`/${getOpenNextPage}`));
                    } else {
                        dispatch(push(`/marathon-about`));
                    }
                } else {
                    dispatch(push(`/${getOpenNextPage}`));
                }

                await initApp();
                dispatch(setStartInfoLoaded());
            })();
        }
    }, [accessToken]);

    const getStudentInfo = async () => {
        const getStudentResult = await getStudent();
        if (getStudentResult.result === 'fail') {
            if (
                getStudentResult.e.error_data !== undefined &&
                getStudentResult.e.error_data.error_reason.error_code === 113
            ) {
                return 'welcome';
            } else {
                await sleep(333);
                await getStudentInfo();
            }
        } else {
            if (getStudentResult.data.student.interest.length === 0) {
                return 'interests';
            } else {
                dispatch(studentSetInterests(getStudentResult.data.student.interest));
                return 'home';
            }
        }
    };

    const getAllCategories = async () => {
        const getCategoriesResult = await getCategories();
        if (getCategoriesResult.result === 'fail') {
            await sleep(333);
            await getAllCategories();
        } else {
            return getCategoriesResult.data;
        }
    };

    const activePanel = Routing().getPanel(pageId);

    return (
        <div id="h-container">
            {activePanel}
            {modal}
        </div>
    );
};

App.propTypes = {
    pageId: PropTypes.string,
};

App.defaultProps = {
    pageId: '',
};

export default App;
