import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/tooltip.scss';

const Tooltip = ({ text, leftArrow, rightArrow, center }) => {
    const varRightArrow = rightArrow ? 'tooltip--right' : '';
    const varLeftArrow = leftArrow ? 'tooltip--left' : '';
    const centerClass = center ? 'tooltip--center' : '';
    return <div className={`tooltip ${varRightArrow}${varLeftArrow}${centerClass}`}>{text}</div>;
};

Tooltip.propTypes = {
    text: PropTypes.string,
    leftArrow: PropTypes.bool,
    rightArrow: PropTypes.bool,
    center: PropTypes.bool,
};

export default Tooltip;
