import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/icon.scss';

export const IconClose = ({ onClick }) => {
    return (
        <div className="icon icon14" onClick={onClick}>
            <svg
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <path
                        d="M14.3,1.7 L14.3,1.7 C13.9134007,1.31340068 13.2865993,1.31340068 12.9,1.7 L8,6.6 L3.1,1.7 C2.71340068,1.31340068 2.08659932,1.31340068 1.7,1.7 L1.7,1.7 C1.31340068,2.08659932 1.31340068,2.71340068 1.7,3.1 L6.6,8 L1.7,12.9 C1.31340068,13.2865993 1.31340068,13.9134007 1.7,14.3 L1.7,14.3 C2.08659932,14.6865993 2.71340068,14.6865993 3.1,14.3 L8,9.4 L12.9,14.3 C13.2865993,14.6865993 13.9134007,14.6865993 14.3,14.3 L14.3,14.3 C14.6865993,13.9134007 14.6865993,13.2865993 14.3,12.9 L9.4,8 L14.3,3.1 C14.6865993,2.71340068 14.6865993,2.08659932 14.3,1.7 Z"
                        id="iconClose"
                    />
                </defs>
                <g id="Курс_прохождение" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Home_3" transform="translate(-340.000000, -298.000000)">
                        <g id="Group-Copy" transform="translate(12.000000, 289.000000)">
                            <g id="Icons-24/cancel_24" transform="translate(327.000000, 8.000000)">
                                <g id="cancel_24">
                                    <rect
                                        id="Bounds_IconClose"
                                        opacity="0.100000001"
                                        x="0"
                                        y="0"
                                        width="16"
                                        height="16"
                                    />
                                    <mask id="mask-2_IconClose" fill="white">
                                        <use xlinkHref="#iconClose" />
                                    </mask>
                                    <use id="Icon_IconClose" fill="#C4C8CC" fillRule="nonzero" xlinkHref="#iconClose" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

IconClose.propTypes = {
    onClick: PropTypes.func,
};

export const IconStar = ({ onClick, color, size, colWhite }) => {
    const colDefault = `icon icon16${color ? ' icon--active' : ''}`;
    const colWhiteVar = `icon white icon16${color ? ' icon--active' : ''}`;
    return (
        <div
            className={colWhite ? colWhiteVar : colDefault}
            style={{ width: `${size}px`, height: `${size}px` }}
            onClick={onClick}
        >
            <svg
                width={size || '24px'}
                height={size || '24px'}
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
            >
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Stars" transform="translate(-61.000000, -1.000000)">
                        <g id="Group-4">
                            <g id="ic/18/star/grey" transform="translate(60.000000, 0.000000)">
                                <polygon id="Path" opacity="0.4" points="0 0 18 0 18 18 0 18" />
                                <path
                                    d="M9.34425,2.46225 L11.187,6.825 L15.4215,7.242 C15.5710911,7.25686284 15.6973493,7.35962183 15.7422813,7.50307747 C15.7872133,7.64653312 15.7421343,7.80295694 15.62775,7.9005 L12.2835,10.7535 L13.4715,15.19875 C13.5122505,15.352113 13.4520594,15.5145233 13.3212033,15.6042869 C13.1903471,15.6940505 13.0171573,15.6917329 12.88875,15.5985 L8.99925,12.7785 L5.109,15.5985 C4.98058222,15.6911429 4.80781271,15.6931391 4.67728872,15.6034879 C4.54676474,15.5138368 4.48661966,15.3518619 4.527,15.19875 L5.71425,10.7535 L2.36925,7.9005 C2.25481296,7.80285816 2.20980074,7.64628112 2.25491273,7.50277268 C2.30002472,7.35926424 2.42652858,7.25660341 2.57625,7.242 L6.81,6.825 L8.6535,2.46225 C8.71218992,2.32349587 8.84821905,2.23333597 8.998875,2.23333597 C9.14953095,2.23333597 9.28556008,2.32349587 9.34425,2.46225 Z"
                                    id="Path"
                                    stroke="#D3D3D3"
                                    strokeWidth="1.5"
                                    fill="#D3D3D3"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

IconStar.propTypes = {
    onClick: PropTypes.func,
    color: PropTypes.bool,
    colWhite: PropTypes.string,
    size: PropTypes.number,
};

export const IconProgress = ({ size }) => {
    return (
        <div className="icon" style={{ width: `${size}px`, height: `${size}px` }}>
            <svg
                width={size}
                height={size}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlinkHref="http://www.w3.org/1999/xlink"
            >
                <desc>Created with Sketch.</desc>
                <g id="Курс_прохождение" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Home_0" transform="translate(-351.000000, -437.000000)" fill="#DDDDDD">
                        <g id="Group-2" transform="translate(0.000000, 418.000000)">
                            <g id="Group-4">
                                <g id="Group-3" transform="translate(351.000000, 19.000000)">
                                    <circle id="ic/12/progress_0" cx={size / 2} cy={size / 2} r={size / 2} />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

IconProgress.propTypes = {
    size: PropTypes.number,
};
