import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';

const BreadcrumbsItem = ({ title, link }) => {
    const dispatch = useDispatch();

    const selectMenu = selectedLink => {
        dispatch(push(`/${selectedLink}`));
    };

    const renderBreadcrumbsItem = (breadTitle, breadLink) => {
        const click = () => {
            selectMenu(breadLink);
        };
        return (
            <div className="breadcrumbs--item" onClick={click} key={`top-menu-key-${breadLink}`}>
                {breadTitle}
            </div>
        );
    };

    const breadcrumbsItem = () => {
        const item = [];
        item.push(renderBreadcrumbsItem(title, link));
        return item;
    };

    return <>{breadcrumbsItem(renderBreadcrumbsItem())}</>;
};

BreadcrumbsItem.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
};

export default BreadcrumbsItem;
