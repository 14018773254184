import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { homeAboutSetIsNextCourseStart, homeSelectMarathon } from '../store/home/actions';
import WhiteBlock from './WhiteBlock';
import MarathonItem from './MarathonItem';
import { goToTop } from '../services/vk';
import '../../styles/marathon-item.scss';

const MarathonItemList = ({ items, active }) => {
    const dispatch = useDispatch();

    const openMarathonAbout = course => {
        dispatch(homeSelectMarathon(course));
        if (active) dispatch(homeAboutSetIsNextCourseStart(true));
        dispatch(push('/marathon-about'));
        goToTop();
    };

    const renderList = () =>
        items.map(c => (
            <MarathonItem
                key={`mi${c.course_id}`}
                onClick={() => openMarathonAbout(c)}
                image={c.cover_photo !== undefined && c.cover_photo[0].b !== undefined ? c.cover_photo[0].b.url : ''}
                title={c.title}
                exercise={c.step_count !== undefined ? c.step_count : 0}
                student={c.students_count}
                rating={c.rating}
            />
        ));

    return (
        <WhiteBlock title="Последние марафоны">
            <div className="marathon-item--list">
                <div className="marathon-item--list-inner">{renderList()}</div>
            </div>
        </WhiteBlock>
    );
};

MarathonItemList.propTypes = {
    items: PropTypes.array,
    active: PropTypes.bool,
};

export default MarathonItemList;
