import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COURSES_RECOMMENDED_PER_PAGE } from '../../services/_globals';
import WhiteBlockBread from '../../components/WhiteBlockBread';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import BlockAuthor from '../../components/BlockAuthor';
import MarathonItemListPag from '../../components/MarathonItemListPag';
import { getAboutAuthorCourses, getPublic, isPublicMember, resizeFrame } from '../../services/vk';
import {
    homeAboutSetAuthor,
    homeAboutSetAuthorBread,
    homeAboutSetAuthorIsMember,
    homeAboutSetAuthorMarathons,
    homeResetCourseInfo,
} from '../../store/home/actions';

const PanelAuthorAbout = () => {
    const dispatch = useDispatch();
    const selectedAuthorId = useSelector(state => state.home.selectedAuthorId);
    const aboutAuthor = useSelector(state => state.home.aboutAuthor);
    const aboutAuthorMarathons = useSelector(state => state.home.aboutAuthorMarathons);
    const aboutAuthorBread = useSelector(state => state.home.aboutAuthorBread);
    const aboutAuthorIsMember = useSelector(state => state.home.aboutAuthorIsMember);
    const vkId = useSelector(state => state.vk.vkId);

    useEffect(() => {
        resizeFrame();

        dispatch(homeResetCourseInfo());

        if (aboutAuthorBread === '') {
            (async () => {
                // Получаем марафоны
                const getAuthorMarathonsResult = await getAboutAuthorCourses(selectedAuthorId, 0);
                if (getAuthorMarathonsResult.result === 'success') {
                    dispatch(homeAboutSetAuthorMarathons(getAuthorMarathonsResult.data));
                    resizeFrame();
                }

                // Получаем инфу паблика
                const getPublicResult = await getPublic(-selectedAuthorId);
                if (getPublicResult.result === 'success') {
                    dispatch(homeAboutSetAuthor(getPublicResult.data[0]));
                    dispatch(homeAboutSetAuthorBread(getPublicResult.data[0].name));
                    resizeFrame();

                    // Проверяем участник паблика или нет
                    if (getPublicResult.data[0].is_closed === 0) {
                        const isPublicMemberResult = await isPublicMember(-selectedAuthorId, vkId);
                        if (isPublicMemberResult.result === 'success') {
                            dispatch(homeAboutSetAuthorIsMember(!!isPublicMemberResult.data));
                        }
                    }
                }
            })();
        }
    }, []);

    const changeAuthorMarathonsPage = async num => {
        const getAuthorMarathonsResult = await getAboutAuthorCourses(
            selectedAuthorId,
            (num - 1) * COURSES_RECOMMENDED_PER_PAGE,
        );

        if (getAuthorMarathonsResult.result === 'success') {
            dispatch(homeAboutSetAuthorMarathons(getAuthorMarathonsResult.data));
            resizeFrame();
        }
    };

    return (
        <>
            <WhiteBlockBread
                breadcrumbs={
                    <>
                        <BreadcrumbsItem title="VK Edu" link="home" />
                        <BreadcrumbsItem
                            title={aboutAuthor !== undefined ? aboutAuthor.name : ''}
                            link="author-about"
                        />
                    </>
                }
            >
                <BlockAuthor
                    id={aboutAuthor.id}
                    cover={aboutAuthor.cover}
                    avatar={aboutAuthor !== undefined ? aboutAuthor.photo_100 : ''}
                    name={aboutAuthor !== undefined ? aboutAuthor.name : ''}
                    marathonNum={aboutAuthorMarathons.total}
                    marathonPpl={aboutAuthor !== undefined ? aboutAuthor.members_count : undefined}
                    isInvite={aboutAuthorIsMember}
                />
            </WhiteBlockBread>
            {aboutAuthorMarathons.total > 0 && (
                <MarathonItemListPag
                    items={aboutAuthorMarathons.items}
                    total={aboutAuthorMarathons.total}
                    offset={aboutAuthorMarathons.offset}
                    selectPageFunc={changeAuthorMarathonsPage}
                    title="Марафоны"
                />
            )}
        </>
    );
};

export default PanelAuthorAbout;
