import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import { COURSES_RECOMMENDED_PER_PAGE } from '../../services/_globals';
import MarathonItemList from '../../components/MarathonItemList';
import BlockBestAuthorList from '../../components/BlockBestAuthorList';
import MarathonItemListPag from '../../components/MarathonItemListPag';
import BlockSearch from '../../components/BlockSearch';
import CustomSelect from '../../components/CustomSelect';
import WhiteBlock from '../../components/WhiteBlock';
import CategoryTopMarathon from '../../components/CategoryTopMarathon';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import CategoryCheckItemList from '../../components/CategoryCheckItemList';
import Pagination from '../../components/Pagination';
import ModalError from '../../components/ModalError';
import { openModal, setIsFirstLoadStartInfo, setSubTabs, setTabs } from '../../store/globals/actions';
import {
    getRecommendedCourses,
    getSearchCourses,
    getStartInfo,
    getStudentActiveCourses,
    getStudentCompleteCourses,
    getStudentFavouriteCourses,
    resizeFrame,
    setStudentInterest,
} from '../../services/vk';
import {
    homeMarathonsSetBestAuthors,
    homeMarathonsSetRecommendedCourses,
    homeMarathonsSetStudentActiveCourses,
    homeResetAuthorInfo,
    homeResetCourseInfo,
    homeSecondSetRecommendedCourses,
    homeSetSearchCourses,
    homeSetStudentActiveCourses,
    homeSetStudentCompletedCourses,
    homeSetStudentFavouriteCourses,
} from '../../store/home/actions';
import { coursesArrMock } from '../../services/mock';
import '../../../styles/white-block.scss';
import '../../../styles/head-tabs.scss';

const PanelHome = () => {
    const dispatch = useDispatch();
    const marathonsStudentActiveCourses = useSelector(state => state.home.tabMarathons.studentActiveCourses);
    const marathonsBestAuthors = useSelector(state => state.home.tabMarathons.bestAuthors);
    const marathonsRecommendedCourses = useSelector(state => state.home.tabMarathons.recommendedCourses);
    const topCourse = useSelector(state => state.home.tabSecond.topCourse);
    const secondBestAuthors = useSelector(state => state.home.tabSecond.bestAuthors);
    const secondRecommendedCourses = useSelector(state => state.home.tabSecond.recommendedCourses);
    const tabProfileStudentActiveCourses = useSelector(state => state.home.tabProfile.studentActiveCourses);
    const tabProfileStudentFavouriteCourses = useSelector(state => state.home.tabProfile.studentFavouriteCourses);
    const tabProfileStudentCompletedCourses = useSelector(state => state.home.tabProfile.studentCompletedCourses);
    const tabIndex = useSelector(state => state.globals.tabIndex);
    const tabCategoryIndex = useSelector(state => state.globals.tabCategoryIndex);
    const subTabIndex = useSelector(state => state.globals.subTabIndex);
    const isFirstLoadStartInfo = useSelector(state => state.globals.isFirstLoadStartInfo);
    const selectedCategoriesIds = useSelector(state => state.student.selectedCategoriesIds);
    const searchText = useSelector(state => state.home.search.searchText);
    const searchIsLoading = useSelector(state => state.home.search.searchIsLoading);
    const searchCourses = useSelector(state => state.home.search.searchCourses);
    const [isLoading, setIsLoading] = useState(false);
    const tabIndexFirstStart = useRef(true);

    useEffect(() => {
        dispatch(homeResetCourseInfo());
        dispatch(homeResetAuthorInfo());

        if (!isFirstLoadStartInfo) {
            loadContent();
        } else {
            dispatch(setIsFirstLoadStartInfo(false));
        }

        resizeFrame();
    }, []);

    useEffect(() => {
        resizeFrame();
        if (!tabIndexFirstStart.current) {
            loadContent();
        } else {
            tabIndexFirstStart.current = false;
        }
    }, [tabIndex, subTabIndex]);

    const loadContent = async () => {
        if (tabIndex === 1) {
            const startInfoResult = await getStartInfo();
            if (startInfoResult.result === 'success') {
                dispatch(homeMarathonsSetStudentActiveCourses(startInfoResult.data.active.items.slice(0, 3)));
                dispatch(
                    homeMarathonsSetBestAuthors(
                        startInfoResult.data.authors.items.slice(0, 5),
                        startInfoResult.data.groups,
                    ),
                );
                dispatch(homeMarathonsSetRecommendedCourses(startInfoResult.data.recommended));
            }
        }

        if (tabIndex === 3) {
            if (subTabIndex === 1) {
                if (process.env.NODE_ENV === 'development') {
                    dispatch(homeSetStudentActiveCourses(coursesArrMock()));
                } else {
                    const studentActiveCoursesResult = await getStudentActiveCourses();
                    if (studentActiveCoursesResult.result === 'success') {
                        dispatch(homeSetStudentActiveCourses(studentActiveCoursesResult.data));
                    }
                }
            } else if (subTabIndex === 2) {
                if (process.env.NODE_ENV === 'development') {
                    dispatch(homeSetStudentFavouriteCourses(coursesArrMock()));
                } else {
                    const studentFavouriteCoursesResult = await getStudentFavouriteCourses();
                    if (studentFavouriteCoursesResult.result === 'success') {
                        dispatch(homeSetStudentFavouriteCourses(studentFavouriteCoursesResult.data));
                    }
                }
            } else if (subTabIndex === 3) {
                if (process.env.NODE_ENV === 'development') {
                    dispatch(homeSetStudentCompletedCourses(coursesArrMock()));
                } else {
                    const studentCompletedCoursesResult = await getStudentCompleteCourses();
                    if (studentCompletedCoursesResult.result === 'success') {
                        dispatch(homeSetStudentCompletedCourses(studentCompletedCoursesResult.data));
                    }
                }
            } else if (subTabIndex === 3) {
            }
        }

        resizeFrame();
    };

    const selectTab = tabIndex => {
        dispatch(setTabs(tabIndex));
    };

    const changeMarathonRecommendedPage = async num => {
        const recommendedResult = await getRecommendedCourses(0, (num - 1) * COURSES_RECOMMENDED_PER_PAGE);
        if (recommendedResult.result === 'success') {
            dispatch(homeMarathonsSetRecommendedCourses(recommendedResult.data));
            resizeFrame();
        }
    };

    const changeSecondRecommendedPage = async num => {
        const recommendedResult = await getRecommendedCourses(
            tabCategoryIndex,
            (num - 1) * COURSES_RECOMMENDED_PER_PAGE + 1,
        );
        if (recommendedResult.result === 'success') {
            dispatch(homeSecondSetRecommendedCourses(recommendedResult.data));
            resizeFrame();
        }
    };

    const changeProfileActivePage = async num => {
        const studentActiveCoursesResult = await getStudentActiveCourses((num - 1) * COURSES_RECOMMENDED_PER_PAGE);
        if (studentActiveCoursesResult.result === 'success') {
            dispatch(homeSetStudentActiveCourses(studentActiveCoursesResult.data));
            resizeFrame();
        }
    };

    const changeProfileFavouritePage = async num => {
        const studentFavouriteCoursesResult = await getStudentFavouriteCourses(
            (num - 1) * COURSES_RECOMMENDED_PER_PAGE,
        );
        if (studentFavouriteCoursesResult.result === 'success') {
            dispatch(homeSetStudentFavouriteCourses(studentFavouriteCoursesResult.data));
            resizeFrame();
        }
    };

    const changeProfileCompletedPage = async num => {
        const studentCompletedCoursesResult = await getStudentCompleteCourses((num - 1) * COURSES_RECOMMENDED_PER_PAGE);
        if (studentCompletedCoursesResult.result === 'success') {
            dispatch(homeSetStudentCompletedCourses(studentCompletedCoursesResult.data));
            resizeFrame();
        }
    };

    const changeSearchPage = async num => {
        const searchCoursesResult = await getSearchCourses(searchText, (num - 1) * COURSES_RECOMMENDED_PER_PAGE);
        if (searchCoursesResult.result === 'success') {
            dispatch(homeSetSearchCourses(searchCoursesResult.data));
            resizeFrame();
        }
    };

    const saveStudentInterests = async () => {
        setIsLoading(true);
        const setInterestResult = await setStudentInterest(selectedCategoriesIds);
        setIsLoading(false);

        if (setInterestResult.result === 'fail' || setInterestResult.data !== 1) {
            dispatch(openModal(<ModalError title={'Произошла неизвестная ошибка'} />));
        }
    };

    const renderEmpty = title => (
        <div className="empty-marathons">
            <div>У Вас пока нет {title} марафонов</div>
            <div>
                <Button onClick={() => selectTab(1)}>Найти марафон</Button>
            </div>
        </div>
    );

    const renderTab1 = () => (
        <>
            <BlockSearch />
            {searchCourses.items.length > 0 && searchText.length >= 3 && (
                <MarathonItemListPag
                    items={searchCourses.items}
                    total={searchCourses.total}
                    offset={searchCourses.offset}
                    selectPageFunc={changeSearchPage}
                    title=""
                />
            )}
            {searchCourses.items.length === 0 && searchText.length >= 3 && !searchIsLoading && (
                <div className="empty-search">По Вашему запросу ничего не найдено</div>
            )}
            {searchText.length < 3 && marathonsStudentActiveCourses.length > 0 && (
                <MarathonItemList items={marathonsStudentActiveCourses} active />
            )}
            {searchText.length < 3 && marathonsBestAuthors.length > 0 && (
                <BlockBestAuthorList items={marathonsBestAuthors} />
            )}
            {searchText.length < 3 && marathonsRecommendedCourses.total > 0 && (
                <MarathonItemListPag
                    items={marathonsRecommendedCourses.items}
                    total={marathonsRecommendedCourses.total}
                    offset={marathonsRecommendedCourses.offset}
                    selectPageFunc={changeMarathonRecommendedPage}
                />
            )}
        </>
    );

    const renderTab2 = () => (
        <>
            {topCourse.title !== undefined && (
                <WhiteBlock>
                    <CategoryTopMarathon
                        image={
                            topCourse.cover_photo !== undefined && topCourse.cover_photo[0].e !== undefined
                                ? topCourse.cover_photo[0].e.url
                                : ''
                        }
                        title={topCourse.title}
                        student={topCourse.students_count === undefined ? 0 : topCourse.students_count}
                        rating={topCourse.rating}
                        exercise={topCourse.step_count !== undefined ? topCourse.step_count : 0}
                        course={topCourse}
                    />
                </WhiteBlock>
            )}
            {secondBestAuthors.length > 0 && <BlockBestAuthorList items={secondBestAuthors} />}
            {secondRecommendedCourses.total > 0 && (
                <MarathonItemListPag
                    items={secondRecommendedCourses.items}
                    total={secondRecommendedCourses.total - 1}
                    offset={secondRecommendedCourses.offset}
                    selectPageFunc={changeSecondRecommendedPage}
                />
            )}
            {topCourse.title === undefined &&
                secondBestAuthors.length === 0 &&
                secondRecommendedCourses.total === 0 && <div className="empty-marathons">Нет доступных марафонов</div>}
        </>
    );

    const renderTab3 = () => (
        <>
            <Tabs mode="default" type="buttons" className="tabs-header">
                <TabsItem onClick={() => dispatch(setSubTabs(1))} selected={subTabIndex === 1}>
                    Активные
                </TabsItem>
                <TabsItem onClick={() => dispatch(setSubTabs(2))} selected={subTabIndex === 2}>
                    Избранные
                </TabsItem>
                <TabsItem onClick={() => dispatch(setSubTabs(3))} selected={subTabIndex === 3}>
                    Пройденные
                </TabsItem>
                <TabsItem onClick={() => dispatch(setSubTabs(4))} selected={subTabIndex === 4}>
                    Интересы
                </TabsItem>
            </Tabs>
            {subTabIndex === 1 && (
                <div className="tabs-inner">
                    {tabProfileStudentActiveCourses.total > 0 && (
                        <MarathonItemListPag
                            items={tabProfileStudentActiveCourses.items}
                            total={tabProfileStudentActiveCourses.total}
                            offset={tabProfileStudentActiveCourses.offset}
                            selectPageFunc={changeProfileActivePage}
                            title={''}
                            active
                        />
                    )}
                    {tabProfileStudentActiveCourses.total > 0 && (
                        <div className="pagination pagination--profile">
                            <Pagination
                                selectPageFunc={changeProfileActivePage}
                                pageNum={
                                    Math.floor(tabProfileStudentActiveCourses.offset / COURSES_RECOMMENDED_PER_PAGE) + 1
                                }
                                total={tabProfileStudentActiveCourses.total}
                            />
                        </div>
                    )}
                    {tabProfileStudentActiveCourses.total === 0 && renderEmpty('активных')}
                </div>
            )}
            {subTabIndex === 2 && (
                <div className="tabs-inner">
                    {tabProfileStudentFavouriteCourses.total > 0 && (
                        <MarathonItemListPag
                            items={tabProfileStudentFavouriteCourses.items}
                            total={tabProfileStudentFavouriteCourses.total}
                            offset={tabProfileStudentFavouriteCourses.offset}
                            selectPageFunc={changeProfileFavouritePage}
                            title={''}
                        />
                    )}
                    {tabProfileStudentFavouriteCourses.total > 0 && (
                        <div className="pagination pagination--profile">
                            <Pagination
                                selectPageFunc={changeProfileFavouritePage}
                                pageNum={
                                    Math.floor(
                                        tabProfileStudentFavouriteCourses.offset / COURSES_RECOMMENDED_PER_PAGE,
                                    ) + 1
                                }
                                total={tabProfileStudentFavouriteCourses.total}
                            />
                        </div>
                    )}
                    {tabProfileStudentFavouriteCourses.total === 0 && renderEmpty('избранных')}
                </div>
            )}
            {subTabIndex === 3 && (
                <div className="tabs-inner">
                    {tabProfileStudentCompletedCourses.total > 0 && (
                        <MarathonItemListPag
                            items={tabProfileStudentCompletedCourses.items}
                            total={tabProfileStudentCompletedCourses.total}
                            offset={tabProfileStudentCompletedCourses.offset}
                            selectPageFunc={changeProfileCompletedPage}
                            title={''}
                        />
                    )}
                    {tabProfileStudentCompletedCourses.total > 0 && (
                        <div className="pagination pagination--profile">
                            <Pagination
                                selectPageFunc={changeProfileCompletedPage}
                                pageNum={
                                    Math.floor(
                                        tabProfileStudentCompletedCourses.offset / COURSES_RECOMMENDED_PER_PAGE,
                                    ) + 1
                                }
                                total={tabProfileStudentCompletedCourses.total}
                            />
                        </div>
                    )}
                    {tabProfileStudentCompletedCourses.total === 0 && renderEmpty('пройденных')}
                </div>
            )}
            {subTabIndex === 4 && (
                <div className="tabs-inner profile-interests">
                    <CategoryCheckItemList />
                    <div className="tooltip-btn">
                        <ButtonWithLoader
                            className="block-center"
                            disabled={selectedCategoriesIds.length < 3}
                            onClickFunc={saveStudentInterests}
                            isLoading={isLoading}
                        >
                            Сохранить
                        </ButtonWithLoader>
                        {selectedCategoriesIds.length < 3 && (
                            <div className="tooltip-btn--box">Выберите хотя бы три категории</div>
                        )}
                    </div>
                </div>
            )}
        </>
    );

    const renderPaginationForSearch = () => (
        <div className="pagination pagination--home">
            <Pagination
                selectPageFunc={changeSearchPage}
                pageNum={Math.floor(searchCourses.offset / COURSES_RECOMMENDED_PER_PAGE) + 1}
                total={searchCourses.total}
            />
        </div>
    );

    return (
        <>
            <Tabs mode="default" type="buttons" className="tabs-header">
                <TabsItem onClick={() => selectTab(1)} selected={tabIndex === 1}>
                    Марафоны
                </TabsItem>
                <CustomSelect />
                <TabsItem onClick={() => selectTab(3)} selected={tabIndex === 3}>
                    Профиль
                </TabsItem>
                {searchCourses.items.length > 0 && searchText !== '' && tabIndex === 1 && renderPaginationForSearch()}
            </Tabs>
            {tabIndex === 1 && <div className="tabs-inner">{renderTab1()}</div>}
            {tabIndex === 2 && <div className="tabs-inner">{renderTab2()}</div>}
            {tabIndex === 3 && <div className="tabs-inner">{renderTab3()}</div>}
        </>
    );
};

export default PanelHome;
