import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import WhiteBlock from './WhiteBlock';
import BlockBestAuthor from './BlockBestAuthor';
import { homeAboutSelectAuthorId } from '../store/home/actions';

const BlockBestAuthorList = ({ items }) => {
    const dispatch = useDispatch();

    const openAuthor = authorId => {
        dispatch(homeAboutSelectAuthorId(authorId));
        dispatch(push('/author-about'));
    };

    const renderList = () =>
        items.map(a => (
            <BlockBestAuthor
                key={`ba${a.id}`}
                onClick={() => openAuthor(a.id)}
                image={a.photo_200}
                title={a.name}
                ppl={a.members_count}
            />
        ));

    return (
        <WhiteBlock title="Лучшие авторы">
            <div className="best-author--inner">{renderList()}</div>
        </WhiteBlock>
    );
};

BlockBestAuthorList.propTypes = {
    items: PropTypes.array,
};

export default BlockBestAuthorList;
