import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import RatingStars from './RatingStars';
import ModalSubs from './ModalSubs';
import { numberWithKM, wordPad } from '../services/_functions';
import { openModal } from '../store/globals/actions';
import { studentAddCourseToFavourite, studentLeaveCourse, studentRemoveCourseToFavourite } from '../services/vk';
import {
    homeAboutSetSelectedMarathonIsActiveCourse,
    homeAboutSetSelectedMarathonIsFavourite,
} from '../store/home/actions';
import '../../styles/author-about.scss';

const BlockMarathon = ({
    id,
    cover,
    avatar,
    name,
    students,
    rating,
    authorName,
    marathonNum,
    marathonPpl,
    video,
    desc,
    done,
    isActiveCourse,
    isFavourite,
}) => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);

    const openPublic = () => {
        window.open(`https://vk.com/club${id}`, '_blank');
    };

    const renderVideo = () => {
        return <iframe src={video} width="367" height="212" frameBorder="0" allowFullScreen></iframe>;
    };

    const joinMarathon = () => {
        dispatch(openModal(<ModalSubs top={150} id={id} />));
    };

    const leaveMarathon = async () => {
        const leaveResult = await studentLeaveCourse(-id, selectedMarathon.course_id);
        if (leaveResult.result === 'success' && leaveResult.data === 1) {
            dispatch(homeAboutSetSelectedMarathonIsActiveCourse(false));
        }
    };

    const addToFaves = async () => {
        const addToFavesResult = await studentAddCourseToFavourite(-id, selectedMarathon.course_id);
        if (addToFavesResult.result === 'success' && addToFavesResult.data === 1) {
            dispatch(homeAboutSetSelectedMarathonIsFavourite(true));
        }
    };

    const removeFromFaves = async () => {
        const removeFromFavesResult = await studentRemoveCourseToFavourite(-id, selectedMarathon.course_id);
        if (removeFromFavesResult.result === 'success' && removeFromFavesResult.data === 1) {
            dispatch(homeAboutSetSelectedMarathonIsFavourite(false));
        }
    };

    return (
        <div className="marathon">
            <div className="marathon--cover" style={{ backgroundImage: `url(${cover})` }}>
                <div className="marathon--about">
                    <div className="marathon--name">{name}</div>
                    <div className="marathon--stats">
                        <div className="marathon--rating">
                            <span>{students + wordPad(students, ' студент', ' студента', ' студентов')}</span>
                            <RatingStars size={12} colWhite="fff" rating={rating} />
                        </div>
                        <div className="marathon--action">
                            {isFavourite && (
                                <Button mode="tertiary" onClick={removeFromFaves}>
                                    В избранном
                                </Button>
                            )}
                            {!isFavourite && (
                                <Button mode="tertiary" onClick={addToFaves}>
                                    Добавить в избранное
                                </Button>
                            )}
                            {!done && isActiveCourse && (
                                <Button mode="overlay_primary opacity" onClick={leaveMarathon}>
                                    Вы подписаны на марафон
                                </Button>
                            )}
                            {!done && !isActiveCourse && (
                                <Button mode="overlay_primary" onClick={joinMarathon}>
                                    Подписаться
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="marathon--info">
                {video !== '' ? <div className="marathon--video">{renderVideo()}</div> : null}
                <div className="marathon--description">
                    {desc}
                    {done ? (
                        <div className="marathon--done">
                            Марафон пройден
                            <Icon16Done fill="939393" width={10} height={10} />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="author--about marathoned">
                <div onClick={openPublic} className="author--avatar" style={{ backgroundImage: `url(${avatar})` }} />
                <div className="author--info">
                    <div onClick={openPublic} className="author--name">
                        {authorName}
                    </div>
                    <div className="author--stats">
                        {marathonNum + wordPad(marathonNum, ' марафон', ' марафона', ' марафонов')}
                        {marathonPpl !== undefined
                            ? '· ' +
                              numberWithKM(marathonPpl) +
                              (marathonPpl < 1000
                                  ? wordPad(marathonPpl, ' подписчик', ' подписчика', ' подписчиков')
                                  : ' подписчиков')
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockMarathon.propTypes = {
    id: PropTypes.number,
    cover: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    students: PropTypes.number,
    rating: PropTypes.number,
    video: PropTypes.string,
    done: PropTypes.bool,
    authorName: PropTypes.string,
    marathonNum: PropTypes.number,
    marathonPpl: PropTypes.number,
    isFinished: PropTypes.bool,
    isActiveCourse: PropTypes.bool,
    isFavourite: PropTypes.bool,
};

export default BlockMarathon;
