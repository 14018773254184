import * as types from './actionTypes';

const initialState = {
    modal: '',
    tabIndex: 1,
    tabCategoryIndex: 0,
    tabCategoryTitle: '',
    subTabIndex: 1,
    isStartInfoLoaded: false,
    isFirstLoadStartInfo: true,
    preloadAuthorId: 0,
    preloadCourseId: 0,
    preloadStepId: 0,
    isNeedPreload: false,
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            document.body.classList.add('modal--open');
            return {
                ...state,
                modal: action.modal,
            };

        case types.CLOSE_MODAL:
            document.body.classList.remove('modal--open');
            return {
                ...state,
                modal: null,
            };

        case types.SET_TAB:
            return {
                ...state,
                tabIndex: action.tabIndex,
            };

        case types.SET_SUBTAB:
            return {
                ...state,
                subTabIndex: action.subTabIndex,
            };

        case types.SET_START_INFO_LOADED: {
            return {
                ...state,
                isStartInfoLoaded: true,
            };
        }

        case types.SET_TAB_CATEGORY:
            return {
                ...state,
                tabCategoryIndex: action.tabCategoryIndex,
                tabCategoryTitle: action.tabCategoryTitle,
            };

        case types.SET_IS_FIRST_LOAD_START_INFO:
            return {
                ...state,
                isFirstLoadStartInfo: action.isFirstLoadStartInfo,
            };

        case types.SET_IS_NEED_PRELOAD:
            return {
                ...state,
                preloadAuthorId: action.preloadAuthorId,
                preloadCourseId: action.preloadCourseId,
                preloadStepId: action.preloadStepId,
                isNeedPreload: action.isNeedPreload,
            };

        default:
            return state;
    }
};
