import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import DropButton from './DropButton';
import { numberWithKM, wordPad } from '../services/_functions';
import { joinGroup } from '../services/vk';
import { homeAboutSetAuthorIsMember } from '../store/home/actions';
import '../../styles/author-about.scss';

const BlockAuthor = ({ id, cover, avatar, name, marathonNum, marathonPpl, isInvite }) => {
    const dispatch = useDispatch();

    const joinThisGroup = async () => {
        const joinResult = await joinGroup(id);
        if (joinResult) {
            dispatch(homeAboutSetAuthorIsMember(true));
        }
    };

    const openPublic = () => {
        window.open(`https://vk.com/club${id}`, '_blank');
    };

    return (
        <div className="author">
            {cover !== undefined && cover.enabled === 1 && (
                <div className="author--cover" style={{ backgroundImage: `url(${cover.images[3].url})` }} />
            )}
            <div className="author--about">
                <div onClick={openPublic} className="author--avatar" style={{ backgroundImage: `url(${avatar})` }} />
                <div className="author--info">
                    <div onClick={openPublic} className="author--name">
                        {name}
                    </div>
                    <div className="author--stats">
                        {marathonNum + wordPad(marathonNum, ' марафон', ' марафона', ' марафонов')}
                        {marathonPpl !== undefined
                            ? '· ' +
                              numberWithKM(marathonPpl) +
                              (marathonPpl < 1000
                                  ? wordPad(marathonPpl, ' подписчик', ' подписчика', ' подписчиков')
                                  : ' подписчиков')
                            : ''}
                    </div>
                </div>
                {isInvite !== -1 && isInvite && <DropButton groupId={id} />}
                {isInvite !== -1 && !isInvite && <Button onClick={joinThisGroup}>Подписаться</Button>}
            </div>
        </div>
    );
};

BlockAuthor.propTypes = {
    id: PropTypes.number,
    cover: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    marathonNum: PropTypes.number,
    marathonPpl: PropTypes.number,
    isInvite: PropTypes.oneOfType(PropTypes.number, PropTypes.bool),
};

export default BlockAuthor;
