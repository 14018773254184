import React from 'react';
import PanelEmpty from '../containers/PanelEmpty';
import PanelWelcome from '../containers/register/PanelWelcome';
import PanelInterests from '../containers/register/PanelInterests';
import PanelHome from '../containers/home/PanelHome';
import PanelMarathonAbout from '../containers/home/PanelMarathonAbout';
import PanelAuthorAbout from '../containers/home/PanelAuthorAbout';
import PanelTest from '../containers/test/PanelTest';

const Routing = () => {
    const getPanel = page => {
        switch (page) {
            default:
            case '':
                return <PanelEmpty />;
            case 'welcome':
                return <PanelWelcome />;
            case 'interests':
                return <PanelInterests />;
            case 'home':
                return <PanelHome />;
            case 'marathon-about':
                return <PanelMarathonAbout />;
            case 'author-about':
                return <PanelAuthorAbout />;
            case 'test':
                return <PanelTest />;
        }
    };

    return { getPanel };
};

export default Routing;
