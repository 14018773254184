import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import { openModal } from '../store/globals/actions';
import { openTest } from '../services/_functions';
import ModalNeedPodpiska from './ModaNeedPodpiska';

const MarathonProgramItem = ({ num, name, test, done, testDone, articleUrl }) => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);

    const openThisArticle = async i => {
        if (
            selectedMarathon.is_complete_course ||
            (selectedMarathon.is_active_course !== undefined && selectedMarathon.is_active_course)
        ) {
            dispatch(openTest(test, articleUrl, name, selectedMarathon.is_complete_course));
        } else {
            const el = document.querySelector('.white-block-main');
            dispatch(openModal(<ModalNeedPodpiska top={el.clientHeight - 200 + i * 45} />));
        }
    };

    return (
        <div
            className={`marathon-program--item${selectedMarathon.is_complete_course ? '' : ' inactive'}`}
            onClick={() => openThisArticle(num)}
        >
            <div className="marathon-program--name">{name}</div>
            <div className="marathon-program--action">
                {test && 'Есть тест'}
                {test && testDone && (
                    <>
                        <span>·</span>Пройдено
                        <Icon16Done fill="939393" width={10} height={10} />
                    </>
                )}
                {!test && done && (
                    <>
                        Пройдено
                        <Icon16Done fill="939393" width={10} height={10} />
                    </>
                )}
            </div>
        </div>
    );
};

MarathonProgramItem.propTypes = {
    name: PropTypes.string,
    test: PropTypes.bool,
    done: PropTypes.bool,
    testDone: PropTypes.bool,
    articleUrl: PropTypes.string,
    num: PropTypes.number,
};

export default MarathonProgramItem;
