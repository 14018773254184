import React from 'react';
import PropTypes from 'prop-types';
import { COURSES_RECOMMENDED_PER_PAGE } from '../services/_globals';

const Pagination = ({ pageNum, total, selectPageFunc }) => {
    const selectPage = i => {
        selectPageFunc(i);
    };

    const renderPages = () => {
        const pages = [];

        // Получаем номера промежуточных страниц
        const amount = 5;
        const arrOfPages = [];
        for (let i = pageNum - Math.floor(amount / 2); i <= pageNum + Math.floor(amount / 2); i++) {
            arrOfPages.push(i);
        }

        // Добавляем страницы в массив
        for (let i = 1; i <= Math.ceil(total / COURSES_RECOMMENDED_PER_PAGE); i++) {
            const pagKey = `pag-${i}`;
            if (arrOfPages.includes(i)) {
                pages.push(
                    <span className={pageNum === i ? 'active' : ''} onClick={() => selectPage(i)} key={pagKey}>
                        {i}
                    </span>,
                );
            }

            // Стрелка влево
            if (i === 1 && !arrOfPages.includes(i)) {
                pages.push(
                    <span onClick={() => selectPage(i)} key={`pag-left`}>
                        «
                    </span>,
                );
            }

            // Стрелка вправо
            if (i === Math.ceil(total / COURSES_RECOMMENDED_PER_PAGE) && !arrOfPages.includes(i)) {
                pages.push(
                    <span onClick={() => selectPage(i)} key={`pag-right`}>
                        »
                    </span>,
                );
            }
        }

        return pages;
    };

    return renderPages();
};

Pagination.propTypes = {
    pageNum: PropTypes.number,
    total: PropTypes.number,
};

export default Pagination;
