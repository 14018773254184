import * as types from './actionTypes';

export const setAuthTokenSuccess = token => ({
    type: types.VK_ACCESS_TOKEN_GET_SUCCESS,
    accessToken: token,
});

export const setAuthTokenFail = () => ({
    type: types.VK_ACCESS_TOKEN_GET_FAIL,
});

export const setAppId = appId => ({
    type: types.VK_SET_APP_ID,
    appId,
});

export const setVkId = vkId => ({
    type: types.VK_SET_VK_ID,
    vkId,
});
