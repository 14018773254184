import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Radio from '@vkontakte/vkui/dist/components/Radio/Radio';
import WhiteBlockBread from '../../components/WhiteBlockBread';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import AnswerOption from '../../components/AnswerOption';
import Tooltip from '../../components/Tooltip';
import ModalDoneExercise from '../../components/ModalDoneExercise';
import { resizeFrame, submitQuestionMatch, submitQuestionSelect, submitQuestionText } from '../../services/vk';
import { testNextQuestion, testSetPrevTestInfo } from '../../store/test/actions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { homeAboutSetCourseStepsIsStepPassed } from '../../store/home/actions';
import { openModal } from '../../store/globals/actions';
import '../../../styles/test-layer.scss';
import ModalDoneMarathon from '../../components/ModalDoneMarathon';

const PanelTest = () => {
    const dispatch = useDispatch();
    const breadcumb = useSelector(state => state.test.breadcumb);
    const questions = useSelector(state => state.test.test.items);
    const qNum = useSelector(state => state.test.qNum);
    const stepId = useSelector(state => state.test.stepId);
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);
    const isTestShowOnly = useSelector(state => state.test.isTestShowOnly);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [optionsSelected, setOptionsSelected] = useState([]);
    const [textAnswer, setTextAnswer] = useState('');
    const [pairsLeft, setPairsLeft] = useState([]);
    const [pairsRight, setPairsRight] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [rightQuestions, setRightQuestions] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [lastTotalQuestions, setLastTotalQuestions] = useState(0);
    const [lastRightQuestions, setLastRightQuestions] = useState(0);

    useEffect(() => {
        dispatch(testSetPrevTestInfo(0, 0, 0));
    }, []);

    useEffect(() => {
        recalcHeights();
    }, [pairsRight]);

    useEffect(() => {
        if (questions[qNum] !== undefined && questions[qNum].type === 'match') {
            // Делим массив на левый и правый
            const pLeft = [];
            const pRight = [];
            questions[qNum].pairs.forEach(p => {
                pLeft.push({ id: p.pair[0].id.toString(), text: p.pair[0].text });
                pRight.push({ id: p.pair[1].id.toString(), text: p.pair[1].text });
            });
            setPairsLeft(pLeft);
            setPairsRight(pRight);

            setIsSubmitDisabled(false);
        }
        (async () => {
            if (qNum >= questions.length && questions.length > 0) {
                dispatch(push('/marathon-about'));
                if (!isTestShowOnly) {
                    dispatch(homeAboutSetCourseStepsIsStepPassed(stepId));
                    dispatch(
                        openModal(
                            <ModalDoneExercise
                                questionFrom={rightQuestions}
                                questionTo={totalQuestions}
                                nextModal={
                                    isLastStep ? (
                                        <ModalDoneMarathon
                                            questionFrom={lastRightQuestions}
                                            questionTo={lastTotalQuestions}
                                        />
                                    ) : null
                                }
                            />,
                        ),
                    );
                }
            }
        })();
    }, [qNum, questions]);

    const recalcHeights = () => {
        const leftElements = document.querySelectorAll('.pairs--column.left .pairs--content-box .pairs--box');
        const heightLeft = [];
        leftElements.forEach(el => {
            heightLeft.push(el.clientHeight);
        });
        const rightElements = document.querySelectorAll('.pairs--column.right .pairs--content-box .pairs--box');
        const heightRight = [];
        rightElements.forEach(el => {
            heightRight.push(el.clientHeight);
        });
        leftElements.forEach((el, i) => {
            el.style.marginBottom = (heightLeft[i] > heightRight[i] ? 0 : heightRight[i] - heightLeft[i]) + 15 + 'px';
        });
        rightElements.forEach((el, i) => {
            el.style.marginBottom = (heightRight[i] > heightLeft[i] ? 0 : heightLeft[i] - heightRight[i]) + 15 + 'px';
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = reorder(pairsRight, result.source.index, result.destination.index);

        setPairsRight(items);
    };

    const submitAnswer = async () => {
        if (questions[qNum] !== undefined) {
            switch (questions[qNum].type) {
                case 'text':
                    const updateText = await submitQuestionText(
                        selectedMarathon.author_id,
                        selectedMarathon.course_id,
                        stepId,
                        questions[qNum].question_id,
                        textAnswer,
                    );
                    if (updateText.result === 'success') {
                        setIsSubmitDisabled(true);
                        setTextAnswer('');

                        if (
                            updateText.data.state.step_answers_count !== undefined &&
                            updateText.data.state.step_correct_answers_count !== undefined
                        ) {
                            setTotalQuestions(updateText.data.state.step_answers_count);
                            setRightQuestions(updateText.data.state.step_correct_answers_count);
                        }

                        if (
                            updateText.data.state.status === 'COURSE_DONE' &&
                            updateText.data.state.course_answers_count !== undefined &&
                            updateText.data.state.course_correct_answers_count !== undefined
                        ) {
                            setLastTotalQuestions(updateText.data.state.course_answers_count);
                            setLastRightQuestions(updateText.data.state.course_correct_answers_count);
                            setIsLastStep(true);
                        }

                        dispatch(testNextQuestion());
                    }
                    break;
                case 'select':
                    const updateSelect = await submitQuestionSelect(
                        selectedMarathon.author_id,
                        selectedMarathon.course_id,
                        stepId,
                        questions[qNum].question_id,
                        optionsSelected,
                    );
                    if (updateSelect.result === 'success') {
                        setIsSubmitDisabled(true);
                        setOptionsSelected([]);

                        if (
                            updateSelect.data.state.step_answers_count !== undefined &&
                            updateSelect.data.state.step_correct_answers_count !== undefined
                        ) {
                            setTotalQuestions(updateSelect.data.state.step_answers_count);
                            setRightQuestions(updateSelect.data.state.step_correct_answers_count);
                        }

                        if (
                            updateSelect.data.state.status === 'COURSE_DONE' &&
                            updateSelect.data.state.course_answers_count !== undefined &&
                            updateSelect.data.state.course_correct_answers_count !== undefined
                        ) {
                            setLastTotalQuestions(updateSelect.data.state.course_answers_count);
                            setLastRightQuestions(updateSelect.data.state.course_correct_answers_count);
                            setIsLastStep(true);
                        }

                        dispatch(testNextQuestion());
                    }
                    break;
                case 'match':
                    const pairsIds = [];
                    pairsLeft.forEach((p, i) => {
                        const pair = [+pairsLeft[i].id, +pairsRight[i].id];
                        pairsIds.push(pair);
                    });
                    const updateMatch = await submitQuestionMatch(
                        selectedMarathon.author_id,
                        selectedMarathon.course_id,
                        stepId,
                        questions[qNum].question_id,
                        pairsIds,
                    );
                    if (updateMatch.result === 'success') {
                        setPairsLeft([]);
                        setPairsRight([]);
                        setIsSubmitDisabled(true);

                        if (
                            updateMatch.data.state.step_answers_count !== undefined &&
                            updateMatch.data.state.step_correct_answers_count !== undefined
                        ) {
                            setTotalQuestions(updateMatch.data.state.step_answers_count);
                            setRightQuestions(updateMatch.data.state.step_correct_answers_count);
                        }

                        if (
                            updateMatch.data.state.status === 'COURSE_DONE' &&
                            updateMatch.data.state.course_answers_count !== undefined &&
                            updateMatch.data.state.course_correct_answers_count !== undefined
                        ) {
                            setLastTotalQuestions(updateMatch.data.state.course_answers_count);
                            setLastRightQuestions(updateMatch.data.state.course_correct_answers_count);
                            setIsLastStep(true);
                        }

                        dispatch(testNextQuestion());
                    }
                    break;
            }
        }
        resizeFrame();
    };

    const nextQuestion = () => {
        dispatch(testNextQuestion());
    };

    const checkQuestion = (id, isChecked) => {
        let newOptions;
        if (isChecked) {
            newOptions = [...optionsSelected, id];
            setOptionsSelected(newOptions);
        } else {
            newOptions = optionsSelected.filter(oid => oid !== id);
            setOptionsSelected(newOptions);
        }

        if (newOptions.length > 0) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    };

    const radioQuestion = e => {
        setOptionsSelected([e.target.value]);
        setIsSubmitDisabled(false);
    };

    const changeAnswer = e => {
        setTextAnswer(e.target.value);
        if (e.target.value !== '') {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    };

    const renderRightPairs = () => {
        if (pairsRight.length > 0) {
            return (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {pairsRight.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div className="pairs--content-box">
                                                <div
                                                    className="pairs--box pairs--drag"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className="icon-reorder" />
                                                    {item.text}
                                                    <Tooltip center text="Перетащите варианты" />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        } else {
            return null;
        }
    };

    const renderAnswers = () => {
        let renderAnswers = null;

        if (questions[qNum] !== undefined) {
            switch (questions[qNum].type) {
                case 'text':
                    renderAnswers = (
                        <div className="form-label">
                            <label>Ответ</label>
                            <input
                                disabled={isTestShowOnly}
                                value={isTestShowOnly ? questions[qNum].answer.student_answer_text : textAnswer}
                                onChange={changeAnswer}
                                placeholder="Введите ответ"
                                maxLength={32}
                            />
                        </div>
                    );
                    break;
                case 'select':
                    if (questions[qNum].is_multi_select) {
                        renderAnswers = questions[qNum].options.map(o => (
                            <AnswerOption
                                disabled={isTestShowOnly}
                                key={`ao${o.id}`}
                                text={o.text}
                                id={o.id}
                                changeFunc={checkQuestion}
                                defaultChecked={
                                    !isTestShowOnly ? false : questions[qNum].answer.student_answer.includes(o.id)
                                }
                            />
                        ));
                        renderAnswers = <div className="test-answer">{renderAnswers}</div>;
                    } else {
                        renderAnswers = questions[qNum].options.map(o => (
                            <Radio
                                className="radio-custom"
                                name="text"
                                key={`aro${o.id}`}
                                value={o.id}
                                onChange={radioQuestion}
                                disabled={isTestShowOnly}
                                defaultChecked={
                                    !isTestShowOnly ? false : questions[qNum].answer.student_answer.includes(o.id)
                                }
                            >
                                {o.text}
                            </Radio>
                        ));
                        renderAnswers = <div className="test-answer">{renderAnswers}</div>;
                    }
                    break;
                case 'match':
                    // Рисуем левую часть
                    let leftHtml = '';
                    if (!isTestShowOnly) {
                        leftHtml = pairsLeft.map(p => (
                            <div className="pairs--content-box">
                                <div className="pairs--box">{p.text}</div>
                            </div>
                        ));
                        leftHtml = <div className="pairs--column left">{leftHtml}</div>;
                    } else {
                        if (pairsLeft.length > 0) {
                            const leftStaticPart = [];
                            const partLeftNormalized = {};
                            pairsLeft.forEach(p => {
                                partLeftNormalized[p.id] = p;
                            });
                            questions[qNum].answer.student_answer_match_pairs.forEach(ps => {
                                leftStaticPart.push(partLeftNormalized[ps.left]);
                            });
                            leftHtml = leftStaticPart.map(p => (
                                <div className="pairs--content-box">
                                    <div className="pairs--box">{p.text}</div>
                                </div>
                            ));
                            leftHtml = <div className="pairs--column left">{leftHtml}</div>;
                        }
                    }

                    // Рисуем правую часть
                    let rightHtml = '';
                    if (!isTestShowOnly) {
                        rightHtml = <div className="pairs--column right">{renderRightPairs()}</div>;
                    } else {
                        if (pairsRight.length > 0) {
                            const rightStaticPart = [];
                            const partRightNormalized = {};
                            pairsRight.forEach(p => {
                                partRightNormalized[p.id] = p;
                            });
                            questions[qNum].answer.student_answer_match_pairs.forEach(ps => {
                                rightStaticPart.push(partRightNormalized[ps.right]);
                            });
                            rightHtml = rightStaticPart.map(p => (
                                <div className="pairs--content-box">
                                    <div className="pairs--box">{p.text}</div>
                                </div>
                            ));
                            rightHtml = <div className="pairs--column right">{rightHtml}</div>;
                        }
                    }

                    renderAnswers = (
                        <div className="test-answer">
                            <div className="test-pairs">
                                {leftHtml}
                                {rightHtml}
                            </div>
                        </div>
                    );

                    break;
            }
        }
        resizeFrame();
        return renderAnswers;
    };

    return (
        <>
            <WhiteBlockBread
                breadcrumbs={
                    <>
                        <BreadcrumbsItem title="Марафон" link="marathon-about" />
                        <BreadcrumbsItem title={breadcumb} link="test" />
                    </>
                }
            >
                <div className="test-layer">
                    <div className="test-layer--content">
                        <div className="test-question">{questions[qNum] !== undefined ? questions[qNum].text : ''}</div>
                        {renderAnswers()}
                    </div>
                    <div className="test-layer--footer">
                        {!isTestShowOnly && qNum === questions.length - 1 && questions.length > 0 && (
                            <Button onClick={submitAnswer} disabled={isSubmitDisabled}>
                                Завершить
                            </Button>
                        )}
                        {!isTestShowOnly && qNum < questions.length - 1 && questions.length > 0 && (
                            <Button onClick={submitAnswer} disabled={isSubmitDisabled}>
                                Ответить
                            </Button>
                        )}
                        {isTestShowOnly && qNum < questions.length - 1 && questions.length > 0 && (
                            <Button onClick={nextQuestion}>Далее</Button>
                        )}
                        {isTestShowOnly && qNum === questions.length - 1 && questions.length > 0 && (
                            <Button onClick={nextQuestion}>Завершить</Button>
                        )}
                    </div>
                </div>
            </WhiteBlockBread>
        </>
    );
};

export default PanelTest;
