import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown';
import { leaveGroup } from '../services/vk';
import { homeAboutSetAuthorIsMember } from '../store/home/actions';
import '../../styles/drop-button.scss';

const DropButton = ({ groupId }) => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isDropdownOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsDropdownOpened(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpened]);

    const toggleDropdown = () => {
        setIsDropdownOpened(!isDropdownOpened);
    };

    const leaveThisGroup = async () => {
        const leaveGroupResult = await leaveGroup(groupId);
        if (leaveGroupResult) {
            dispatch(homeAboutSetAuthorIsMember(false));
            setIsDropdownOpened(false);
        }
    };

    return (
        <div className={`drop-button${isDropdownOpened ? ' opened' : ''}`} ref={wrapperRef}>
            <div className="drop-button--selected" onClick={toggleDropdown}>
                <span>Вы подписаны</span>
                <Icon16Dropdown fill="92a0b1" width={10} height={10} />
            </div>
            <div className="drop-button--menu">
                <div className="drop-button--item" onClick={leaveThisGroup}>
                    Отписаться
                </div>
            </div>
        </div>
    );
};

DropButton.propTypes = {
    groupId: PropTypes.number,
};

export default DropButton;
