import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../../styles/button-with-loader.scss';

const ButtonWithLoader = ({ mode, onClickFunc, isLoading, disabled, children }) => {
    const btnRef = useRef(null);

    const onClick = () => {
        const width = btnRef.current.getBoundingClientRect().width;
        btnRef.current.style.width = width + 'px';
        const height = btnRef.current.getBoundingClientRect().height;
        btnRef.current.style.height = `${height}px`;

        onClickFunc();
    };

    const content = !isLoading ? (
        children
    ) : (
        <div className="points">
            <div className="point" />
            <div className="point" />
            <div className="point" />
        </div>
    );

    return (
        <div className={`button-with-loader${disabled ? ' disabled' : ''}`}>
            <div
                ref={btnRef}
                className={`button${disabled ? ' disabled' : ''}${isLoading ? ' is-loading' : ''} ${
                    mode !== undefined ? mode : ''
                }`}
                onClick={onClick}
            >
                {content}
            </div>
        </div>
    );
};

ButtonWithLoader.propTypes = {
    mode: PropTypes.string,
    onClickFunc: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ButtonWithLoader;
