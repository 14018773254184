import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { globals } from './globals/reducers';
import { vk } from './vk/reducers';
import { student } from './student/reducers';
import { home } from './home/reducers';
import { test } from './test/reducers';

export const rootReducer = combineReducers({
    router: routerReducer,
    globals,
    vk,
    student,
    home,
    test,
});
