import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WhiteBlockBread from '../../components/WhiteBlockBread';
import BreadcrumbsItem from '../../components/BreadcrumbsItem';
import BlockMarathon from '../../components/BlockMarathon';
import MarathonProgramList from '../../components/MarathonProgramList';
import { getAboutAuthorCourses, getCourseSteps, getPublic, resizeFrame } from '../../services/vk';
import {
    homeAboutSetCoursePublic,
    homeAboutSetCourseSteps,
    homeAboutSetIsNextCourseStart,
    homeAboutSetMarathonsAmount,
} from '../../store/home/actions';
import { authorCoursesArrMock, getStepsMock } from '../../services/mock';
import { testReset } from '../../store/test/actions';
import { openTest, sleep } from '../../services/_functions';
import { setIsNeedPreload } from '../../store/globals/actions';
import '../../../styles/marathon-about.scss';

const PanelMarathonAbout = () => {
    const dispatch = useDispatch();
    const selectedMarathon = useSelector(state => state.home.selectedMarathon);
    const aboutCourseSteps = useSelector(state => state.home.aboutCourseSteps);
    const aboutMarathonsAmount = useSelector(state => state.home.aboutMarathonsAmount);
    const aboutCoursePublic = useSelector(state => state.home.aboutCoursePublic);
    const aboutAuthorBread = useSelector(state => state.home.aboutAuthorBread);
    const isNeedPreload = useSelector(state => state.globals.isNeedPreload);
    const preloadStepId = useSelector(state => state.globals.preloadStepId);
    const isNextCourseStart = useSelector(state => state.home.isNextCourseStart);
    const [isLoadingSteps, setIsLoadingSteps] = useState(aboutCourseSteps.length === 0);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            (async () => {
                if (isNeedPreload) {
                    await sleep(1000);
                }

                // Общее количество курсов
                const authorCourses = await getAboutAuthorCourses(selectedMarathon.author_id, 0, 1);
                if (authorCourses.result === 'success') {
                    dispatch(homeAboutSetMarathonsAmount(authorCourses.data.total));
                }

                // Упражнения
                let courseSteps;
                if (aboutCourseSteps.length === 0) {
                    setIsLoadingSteps(true);
                    courseSteps = await getCourseSteps(selectedMarathon.author_id, selectedMarathon.course_id);
                    if (courseSteps.result === 'success') {
                        dispatch(homeAboutSetCourseSteps(courseSteps.data.items));
                    }
                    setIsLoadingSteps(false);
                }

                // Паблик
                const publicInfo = await getPublic(-selectedMarathon.author_id);
                if (publicInfo.result === 'success') {
                    dispatch(homeAboutSetCoursePublic(publicInfo.data[0]));
                }

                if (isNeedPreload) {
                    courseSteps.data.items.forEach(step => {
                        if (+step.step_id === +preloadStepId) {
                            dispatch(
                                openTest(
                                    step.has_test,
                                    step.article_url,
                                    step.title,
                                    selectedMarathon.is_complete_course,
                                ),
                            );
                        }
                    });

                    dispatch(setIsNeedPreload(0, 0, 0, false));
                }

                if (isNextCourseStart) {
                    let isFond = false;
                    courseSteps.data.items.forEach(step => {
                        if (!isFond) {
                            if ((!step.has_test && !step.is_article_read) || (step.has_test && !step.is_test_passed)) {
                                dispatch(
                                    openTest(
                                        step.has_test,
                                        step.article_url,
                                        step.title,
                                        selectedMarathon.is_complete_course,
                                    ),
                                );
                                isFond = true;
                            }
                        }
                    });

                    dispatch(homeAboutSetIsNextCourseStart(false));
                }

                dispatch(testReset());

                resizeFrame();
            })();
        } else {
            dispatch(homeAboutSetMarathonsAmount(authorCoursesArrMock().total));
            dispatch(homeAboutSetCourseSteps(getStepsMock()));
            setIsLoadingSteps(false);
        }

        resizeFrame();
    }, []);

    return (
        <>
            <WhiteBlockBread
                breadcrumbs={
                    <>
                        <BreadcrumbsItem title="VK Edu" link="home" />
                        {aboutAuthorBread !== '' && <BreadcrumbsItem title={aboutAuthorBread} link="author-about" />}
                        <BreadcrumbsItem title={selectedMarathon.title} link="marathon-about" />
                    </>
                }
            >
                <BlockMarathon
                    cover={
                        selectedMarathon.cover_photo !== undefined &&
                        selectedMarathon.cover_photo[0] !== undefined &&
                        selectedMarathon.cover_photo[0].b !== undefined
                            ? selectedMarathon.cover_photo[0].b.url
                            : ''
                    }
                    name={selectedMarathon.title}
                    students={selectedMarathon.students_count}
                    rating={selectedMarathon.rating}
                    video={selectedMarathon.video_url !== undefined ? selectedMarathon.video_url : ''}
                    desc={selectedMarathon.description}
                    done={
                        selectedMarathon.is_complete_course !== undefined ? selectedMarathon.is_complete_course : false
                    }
                    id={aboutCoursePublic.id}
                    avatar={aboutCoursePublic.photo_100}
                    authorName={aboutCoursePublic.name}
                    marathonNum={aboutMarathonsAmount}
                    marathonPpl={aboutCoursePublic !== undefined ? aboutCoursePublic.members_count : undefined}
                    isActiveCourse={
                        selectedMarathon.is_active_course !== undefined ? selectedMarathon.is_active_course : false
                    }
                    isFavourite={selectedMarathon.is_favorite !== undefined ? selectedMarathon.is_favorite : false}
                />
            </WhiteBlockBread>
            {!isLoadingSteps && aboutCourseSteps.length > 0 && <MarathonProgramList />}
        </>
    );
};

export default PanelMarathonAbout;
